﻿html
{
  scrollbar-base-color: #434343;
  scrollbar-arrow-color: #8e8e8e;
  scrollbar-face-color: #8e8e8e;
  scrollbar-track-color: #434343;
  scrollbar-shadow-color: #8e8e8e;
  scrollbar-darkshadow-color: #434343;
  scrollbar-highlight-color: #434343;
  scrollbar-3dlight-color: white;

  /* Prevent font scaling in landscape while allowing user zoom */
  -webkit-text-size-adjust: 100%;
}

/* 滚动条默认显示样式 */

::-webkit-scrollbar-thumb
{
  background-color: #8e8e8e;
  height: 50px;
}

/* 滚动条大小 */

::-webkit-scrollbar
{
  width: 10px;
  height: 8px;
}

/* 滚动框背景样式 */

::-webkit-scrollbar-track-piece
{
  background-color: #434343;
}

/* ul 不斷行样式 */

.ul_style_01 li
{
  position: relative;
  margin: 3px;
  padding: 0 20px 0 12px;
  overflow: hidden;
  color: #174c9c;
  *list-style: none;
}

.ul_style_01 li a
{
  font-size: 0.95em;
  line-height: 1.35em;
  text-decoration: none;
  white-space: nowrap;
}

.ul_style_01 li a:before, .ul_style_01 li a before
{
  content: "";
  position: absolute;
  top: 9px;
  top: -moz-calc(9px);
  _top: 6px;
  left: -8px;
  left: 2px;
  width: 5px;
  height: 5px;
  overflow: hidden;
  background: url(/staticelement/img/main/ico.png) no-repeat 0 -189px;
}

#rwd_header .news_list_01 li a:before, #rwd_header_prog .news_list_01 li a:before
{
  content: "";
  position: absolute;
  top: 9px;
  top: -moz-calc(9px);
  _top: 6px;
  left: -8px;
  width: 5px;
  height: 5px;
  overflow: hidden;
  background: url(/staticelement/img/main/ico.png) no-repeat 0 -189px;
}

body
{
  font-family: DFLiHei, "華康儷黑", "Microsoft JhengHei", "微軟正黑體", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #fff;
}

ul,ol,div,h1,h2,h3,h4,p
{
  margin: 0;
  padding: 0;
}

a
{
  text-decoration: none;
}

a img
{
  border: 0;
}

.center
{
  margin: 0 auto;
  width: 982px;
  padding: 0 10px;
  border-top: 0;
  border-bottom: 0;
}

.rwd_center
{
  margin: 0 auto;
  max-width: 982px;
  padding: 0 10px;
  border-top: 0;
  border-bottom: 0;
}

.float_left
{
  float: left;
}

.float_right
{
  float: right;
}

.non_float
{
  clear: both;
}

.clear
{
  clear: both;
}

.clearfix:after
{
  content: "";
  display: table;
  clear: both;
}

ul.nav li
{
  list-style: none;
  float: left;
  position: relative;
}

.color_red
{
  color: #af1513;
}

.all_text
{
  display: block;
  text-align: right;
  font-size: 0.9em;
  color: #f4967a;
}

/* Main Layout */

/* main_left_col */

[data-eq-state="2-column-grid"] .main_left_col
{
  width: 50%;
  padding-right: 10px;
}

.main_left_col
{
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  float: left;
  margin-top: 10px;
  width: 100%;
}

.header
{
  padding: 4px 0 4px 23px;
  background: #206de1;
}

.header_newcategory
{
  padding: 4px 0 4px 23px;
  background: #164b9b;
}

.header_newsprogcategory
{
  padding: 4px 0 4px 23px;
  background: #008fd5;
}

.header h3, .header_newcategory h3
{
  vertical-align: middle;
  color: white;
  font-size: 1.25rem;
  font-size: 20px;
}

.header h3 a, .header_newcategory h3 a
{
  color: #901414;
}

.header .more, .header_newcategory .more
{
  position: absolute;
  top: 8px;
  top: -webkit-calc(5px);
  top: 2px\9;
  *top: 2px;
  right: 1px;
  color: #333333;
  font-weight: 700;
  font-size: 0.9em;
  background: none;
  padding: 0px;
}

.header .todayInfo
{
  position: absolute;
  top: 2px;
  top: -webkit-calc(2px);
  top: 2px\9;
  *top: 2px;
  right: 1px;
  color: #333333;
  background: none;
  padding: 0px;
}

#select_date
{
  position: relative;
  z-index: 1;
  font-weight: normal;
  font-size: 0.9em;
  margin-left: 8px;
  cursor: pointer;
}

.v2014_video-boxes ul
{
  font-size: 0;
  letter-spacing: -4px;
}

.v2014_video-boxes ul li
{
  display: inline-block;
  vertical-align: top;
  /*
  width: 156px;
  */
  font-size: 16px;
  letter-spacing: 0;
  overflow: hidden;
}

.v2014_video-boxes ul li:hover a
{
  color: #0060b9;
}

*.v2014_video-boxes ul li
{
  *display: inline;
}

.v2014_video-boxes ul li .fig
{
  display: block;
}

.v2014_video-boxes ul li .fig:after, .v2014_video-boxes ul li .fig after
{
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 22px;
  height: 18px;
  background: url(/staticelement/img/main/ico.png) no-repeat 0 -114px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}

.v2014_video-boxes ul li .fig:hover:after, .v2014_video-boxes ul li .fig:hover after
{
  background-position: -32px -114px;
}

.v2014_video-boxes ul li .txt
{
  color: #333333;
  text-decoration: none;
}

.v2014_video-boxes ul li .txt:visited
{
  color: #999;
}

.v2014_video-boxes ul li .fig img
{
  position: absolute;
  width: 100%;
  height: 100%;
}

/* v2014_video-boxes-1 */

.v2014_video-boxes-1
{
}

.v2014_video-boxes-1 ul
{
  font-size: 0;
  margin-top: 20px;
  letter-spacing: -4px;
  border-bottom: 2px solid #ddd;
}

.v2014_video-boxes-1 ul:nth-child(2)
{
  border-bottom: 0;
}

.v2014_video-boxes-1 ul li
{
  display: inline-block;
  vertical-align: top;
  margin-right: 13px;
  padding-bottom: 15px;
  width: 207px;
  font-size: 14px;
  letter-spacing: 0;
  /*
  height: 130px;
  */
  overflow: hidden;
  margin-bottom: 10px;
}

.v2014_video-boxes-1 ul li:hover a
{
  color: #0060b9;
}

*.v2014_video-boxes-1 ul li
{
  *display: inline;
}

.v2014_video-boxes-1 ul li .fig
{
  position: relative;
  display: block;
  overflow: hidden;
  margin-bottom: 4px;
  /*
  height: 130px;
  */
}

.v2014_video-boxes-1 ul li .topic:after, .v2014_video-boxes-1 ul li .topic:after
{
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 58px;
  height: 27px;
  background: url(/staticelement/img/main/topic_bg.png) no-repeat;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}

.v2014_video-boxes-1 ul li .txt
{
  color: #333333;
  font-weight: bold;
  text-decoration: none;
}

.v2014_video-boxes-1 ul li .txt:visited
{
  color: #999;
}

.v2014_video-boxes-1 ul li .fig img
{
  z-index: -1;
  width: 212px;
  top: 105px;
  left: 164px;
}

.v2014_video-boxes-1 ul li .fig .topic_name
{
  position: absolute;
  z-index: 10;
  padding: 5px 8px;
  height: 24px;
  width: 191px;
  top: 100px;
  color: white;
  font-weight: bold;
  font-size: 1em;
  background: url(/staticelement/img/main/gradient_bg.png) repeat-x;
}

@media (max-width:1000px)
{
  .v2014_video-boxes-1 ul li
  {
    /*
    max-width: 290px;
    */
    width: auto;
    overflow: visible;
  }

  .v2014_video-boxes-1 ul li .fig img
  {
    max-width: 290px;
    width: auto;
  }
}

/* rwd */

.rwd_v2014_video_box img
{
  width: 100%;
  height: 100%;
  position: absolute;
}

.rwd_v2014_video_box
{
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  letter-spacing: -4px;
  width: 100%;
  font-size: 18px;
  line-height: 1.35;
  letter-spacing: 0;

  padding: 10px 0;

  float: left;
}

[data-eq-state="2-column-grid"] .rwd_v2014_video_box
{
  padding: 10px 10px;
  font-size: 16px;
  width: 50%;
}

[data-eq-state="3-column-grid"] .rwd_v2014_video_box
{
  padding: 10px 10px;
  font-size: 16px;
  width: 33.33%;
}

.rwd_v2014_video_box .video_box_img_wrapper
{
  position: relative;
  padding-bottom: 66.66667% !important;
}

.rwd_v2014_video_box .video_box_txt_wrapper
{
  padding-top: 5px;
  text-align: justify;

  overflow: hidden;
}

[data-eq-state="2-column-grid"] .rwd_v2014_video_box .video_box_txt_wrapper,
[data-eq-state="3-column-grid"] .rwd_v2014_video_box .video_box_txt_wrapper
{
  height: 43.2px;
}

/* main_center_col */

[data-eq-state="2-column-grid"] .main_center_col
{
  width: 50%;
  padding-left: 10px;
}

.main_center_col
{
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  float: right;
  width: 100%;

  margin-top: 10px;

  overflow: hidden;
}

#v2014_global_content
{
  position: relative;
  width: 320px;
  height: 305px;
  overflow: hidden;
}

#v2014_global_content .topic ul li
{
  margin: 0 0 3px 0;
}

#v2014_global ul.rest
{
  position: relative;
  /*
  padding-top: 8px;
  border-top:dashed 1px #ddd;
  */
}

#v2014_global ul li
{
  position: relative;
  margin-bottom: 6px;
  padding: 0 20px 0 12px;
  overflow: hidden;
  zoom: 1;
}

#v2014_global ul li a
{
  color: #333333;
  font-size: 0.95em;
  line-height: 1.35;
  text-decoration: none;
  white-space: nowrap;
}

#v2014_global ul li:hover, #v2014_global ul li:hover a
{
  color: #0060b9;
}

#v2014_global ul li a:before, #v2014_global ul li a before
{
  content: "";
  position: absolute;
  top: 7px;
  top: -moz-calc(9px);
  _top: 6px;
  left: 1px;
  width: 5px;
  height: 5px;
  overflow: hidden;
  background: url(/staticelement/img/main/ico.png) no-repeat 0 -189px;
}

small.vid
{
  position: relative;
  top: 5px;
  display: inline-block;
  margin-left: 4px;
  width: 20px;
  height: 10px;
  overflow: hidden;
  vertical-align: super;
  text-indent: -5000px;
  background: url(/staticelement/img/main/ico_vid.png) no-repeat;
  *background: none;
  *width: 0;
  *height: 0;
}

.v2014_video-boxes-1 small.vid
{
  /*
  display: block;
  margin-left: 0;
  */
  margin-left: 3px;
}

/* main_vedio_boxes */

.v2014_video-boxes ul.tabs li
{
  width: auto;
  float: left;
  display: inline;
  margin-right: -1px;
  height: 33px;
  padding-bottom: 0;
  margin-bottom: 0;
}

.v2014_video-boxes ul.tabs li a
{
  background-color: #fff;
  padding: 6px 12px 6px;
  *padding: 5px 12px 9px;
  padding: 5px 12px 9px\9;
  float: left;
  cursor: pointer;
  color: #333333;
  font-weight: 700;
  text-decoration: none;
  border: solid #ddd;
  border-width: 1px 1px 0;
  border-radius: 6px 6px 0 0;
}

.v2014_video-boxes ul.tabs li a.current
{
  color: #fff;
  background: #b01414;
}

.v2014_video-boxes .panes div
{
  border-top: 1px solid #ddd;
  clear: both;
}

/* v2014_tab_01 */

#v2014_tab_01
{
  float: left;
  clear: left;
  width: 100%;
  overflow: hidden;
}

#v2014_tab_01 .panes[data-eq-state="4-column-grid"] div ul li
{
  width: 25%;
  padding-left: 12px;
}

#v2014_tab_01 .panes[data-eq-state="3-column-grid"] div ul li
{
  width: 33.33333%;
  padding-left: 12px;
}

#v2014_tab_01 .panes[data-eq-state="3-column-grid"] div ul li:nth-child(n+4)
{
  display: none;
}

#v2014_tab_01 .panes[data-eq-state="2-column-grid"] div ul li
{
  width: 50%;
  padding-left: 12px;
}

#v2014_tab_01 .panes[data-eq-state="2-column-grid"] div ul li:nth-child(n+3)
{
  display: none;
}

#v2014_tab_01 .panes[data-eq-state="1-column-grid"] div ul li:nth-child(n+2)
{
  display: none;
}

#v2014_tab_01 .panes div ul li
{
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  width: 100%;
  border-bottom: none;

  padding-top: 10px;
  padding-right: 10px;
}

#v2014_tab_01 .panes div ul li:first-child
{
}

#v2014_tab_01 .panes div
{
  border-width: 1px 0 0;
}

#v2014_tab_01 .panes div
{
  position: relative;
  display: block;
  left: 0px;
}

#v2014_tab_01 .panes div .more
{
  position: absolute;
  top: -28px;
  right: 0px;
  color: #333333;
  font-weight: 700;
  font-size: 1rem;
  font-size: 16px;
  background: none;
  padding: 0px;
}

.img_wrapper
{
  position: relative;
  padding-bottom: 66.66667%;
}

/* main_right_col */

/* TopNews */

#TopNews
{
  margin-top: 10px;
  width: 300px;

  /*
  height: 277px;
  */
}

#TopNews .tabs li
{
  width: 143px;
  height: 28px;
  border-right: 0px;
  display: block;
  text-align: center;
  float: left;
}

#TopNews .tabs li a
{
  width: 143px;
  height: 28px;
  display: table-cell;
  *display: block;
  vertical-align: middle;
  background-color: white;
  cursor: pointer;
  color: #164b9b;
  font-weight: bold;
  padding: 0;
}

#TopNews .tabs li a.current
{
  /*
  background: url(/staticelement/img/main/tab_01_bg.png) no-repeat;
  */
  background: #164b9b;
  font-weight: bold;
  color: #fff;
}

#TopNews .panes div
{
  clear: both;
  width: 298px;
  border-top: 2px solid #dddddd;
}

#TopNews .panes div ol
{
  width: 294px;
  margin: 6px 12px 6px 0;
}

#TopNews .more_box
{
  display: block;
  height: 25px;
  padding-top: 5px;
  padding-right: 8px;
  text-align: right;
  border-bottom: 1px solid #ddd;
  border-top: 0px;
  background-color: #f6f6f6;
}

#TopNews .more_box a
{
  font-size: 0.9em;
  color: #333136;
}

.panes div ol
{
  *position: relative;
  *top: -30px;
}

.panes div ol li
{
  border-top: 1px dotted #ddd;
  list-style: none;
  counter-increment: rank;
  overflow: hidden;
  *overflow-x: hidden;
}

.panes div ol li a
{
  display: block;
  font-size: 0.95em;
  white-space: nowrap;
  line-height: 44px;
  *line-height: 39px;
}

.panes div ol li a:hover
{
  color: #164b9b;
  text-decoration: none;
}

.panes div ol li:first-child
{
  border-top: none;
}

.panes div ol li a:first-child:before
{
  content: counter(rank);
  position: relative;
  top: -1px;
  margin-right: 10px;
  padding: 2px 6px;
  font: 700 arial, sans-serif;
}

.panes div ol li a:hover:before, .panes div ol li a:hover before
{
}

/* category_today_hot block old css */
/*
#category_today_hot
{
  width: 100%;

  display: none;
}

#category_today_hot .article_item
{
  margin-top: 10px;
}

#category_today_hot .article_item .img_a
{
  display: block;
  float: left;
  overflow: hidden;
  width: 100px;
  height: 100px;
  margin-right: 10px;
}

#category_today_hot .article_item img
{
  height: 100px;
  margin-left: -24px;
}

#category_today_hot .article_item h4
{
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  padding: 5px 0;
  height: 80px;
  overflow: hidden;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 24px;
  font-weight: normal;
}
*/

#today_hot
{
  width: 100%;

  display: none;
}

#today_hot .article_item
{
  margin-top: 10px;
}

#today_hot .article_item .img_a
{
  display: block;
  float: left;
  overflow: hidden;
  width: 100px;
  height: 100px;
  margin-right: 10px;
}

#today_hot .article_item img
{
  height: 100px;
  margin-left: -24px;
}

#today_hot .article_item h4
{
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  padding: 5px 0;
  height: 80px;
  overflow: hidden;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 24px;
  font-weight: normal;
}

#accordion
{
  width: 100%;
  /*
  height: 198px;
  */
  overflow: hidden;
}

#accordion a.banner_btn
{
  display: block;
  width: 100%;
  height: 48px;
}

#accordion a.pane
{
  display: block;
  border: 1px solid #ddd;
  padding: 10px 0 20px 15px;
}

#accordion .morning
{
  background: url('/staticelement/img/morning_banner-bk.jpg') repeat-x;
}

#accordion .afternoon
{
  background: #ffd345;
}

#accordion .night
{
  background: url('/staticelement/img/night_banner-bk.jpg') repeat-x;
}

#category_today_hot,
#category_weekly_hot,
#category_hot
{
  width: 100%;
  display: none;
}

#category_today_hot .article_item,
#category_weekly_hot .article_item,
#category_hot .article_item
{
  margin-top: 10px;

  display: block;
  float: left;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  width: 50%;
}

#category_today_hot .article_item:nth-child(2n+1),
#category_weekly_hot .article_item:nth-child(2n+1),
#category_hot .article_item:nth-child(2n+1)
{
  padding: 0px 5px 0px 0px;
}

#category_today_hot .article_item:nth-child(2n+0),
#category_weekly_hot .article_item:nth-child(2n+0),
#category_hot .article_item:nth-child(2n+0)
{
  padding: 0px 0px 0px 5px;
}

#category_today_hot .article_item .img_wrapper,
#category_weekly_hot .article_item .img_wrapper,
#category_hot .article_item .img_wrapper
{
  position: relative;
  padding-bottom: 66.66667%;
}

#category_today_hot .article_item img,
#category_weekly_hot .article_item img,
#category_hot .article_item img
{
  width: 100%;
  height: 100%;
  position: absolute;
}

#category_today_hot .article_item h4,
#category_weekly_hot .article_item h4,
#category_hot .article_item h4
{
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  padding: 7px 0 0 0;
  height: 78px;
  overflow: hidden;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 24px;
  font-weight: normal;
}

#weekly_hot
{
  width: 100%;

  display: none;
}

#weekly_hot .article_item
{
  margin-top: 10px;

  display: block;
  float: left;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  width: 50%;
}

#weekly_hot .article_item:nth-child(2n+1)
{
  padding: 0px 5px 0px 0px;
}

#weekly_hot .article_item:nth-child(2n+0)
{
  padding: 0px 0px 0px 5px;
}

#weekly_hot .article_item .img_wrapper
{
  position: relative;
  padding-bottom: 66.66667%;
}

#weekly_hot .article_item img
{
  width: 100%;
  height: 100%;
  position: absolute;
}

#weekly_hot .article_item h4
{
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  padding: 7px 0 0 0;
  height: 78px;
  overflow: hidden;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 24px;
  font-weight: normal;
}

#editor_picks
{
  width: 100%;

  display: none;
}

#editor_picks .article_item
{
  margin-top: 10px;

  display: block;
  float: left;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  width: 50%;
}

#editor_picks .article_item:nth-child(2n+1)
{
  padding: 0px 5px 0px 0px;
}

#editor_picks .article_item:nth-child(2n+0)
{
  padding: 0px 0px 0px 5px;
}

#editor_picks .article_item .img_wrapper
{
  position: relative;
  padding-bottom: 66.66667%;
}

#editor_picks .article_item img
{
  width: 100%;
  height: 100%;
  position: absolute;
}

#editor_picks .article_item h4
{
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  padding: 7px 0 0 0;
  height: 78px;
  overflow: hidden;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 24px;
  font-weight: normal;
}



#hot_topics
{
  width: 100%;

  display: none;
}

#hot_topics .article_item
{
  margin-top: 10px;

  display: block;
  float: left;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  width: 50%;
}

#hot_topics .article_item:nth-child(2n+1)
{
  padding: 0px 5px 0px 0px;
}

#hot_topics .article_item:nth-child(2n+0)
{
  padding: 0px 0px 0px 5px;
}

#hot_topics .article_item .img_wrapper
{
  position: relative;
  padding-bottom: 66.66667%;
}

#hot_topics .article_item img
{
  width: 100%;
  height: 100%;
  position: absolute;
}

#hot_topics .article_item h4
{
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  padding: 7px 0 0 0;
  height: 78px;
  overflow: hidden;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 24px;
  font-weight: normal;
}

@media (min-width: 680px) and (max-width: 959px)
{
  /* category_today_hot block old css */
  /*
  #category_today_hot
  {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    width: 50%;
    float: left;

    padding: 0px 5px 0px 5px;
  }
  */

  #today_hot
  {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    width: 50%;
    float: left;

    padding: 0px 5px 0px 5px;
  }

  #accordion
  {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    width: 50%;
    display: block;
    float: left;

    padding: 0px 5px 0px 5px;
  }
  
  #category_hot
  {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    width: 50%;
    float: left;

    padding: 0px 5px 0px 5px;
  }
  
  #category_today_hot,
  #category_weekly_hot
  {
    clear: both;

    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    width: 50%;
    float: left;

    padding: 0px 5px 0px 5px;
  }

  #weekly_hot
  {
    clear: both;

    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    width: 50%;
    float: left;

    padding: 0px 5px 0px 5px;
  }

  #editor_picks
  {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    width: 50%;
    float: left;

    padding: 0px 5px 0px 5px;
  }

  #hot_topics
  {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    width: 50%;
    float: left;

    padding: 0px 5px 0px 5px;
  }
}

@media (max-width:1000px)
{
  #TopNews
  {
    width: 100%;
  }

  #TopNews .tabs li
  {
    width: 50%;
    height: auto;
  }

  #TopNews .panes div
  {
    width: 100%;
  }

  #TopNews .panes div ol
  {
    width: 100%;
  }

  #TopNews .tabs li a
  {
    width: 100%;
    height: auto;
    display: block;
    *display: block;
    padding: 3px 0;
  }
}

.main_rihgt_col
{
  float: right;
  position: relative;
  width: 300px;
  margin: 10px 0 10px 10px;
  overflow: hidden;
}

.rwd_main_rihgt_col
{
  float: right;
  position: relative;
  width: 300px;
  margin: 10px 0 10px 10px;
  overflow: hidden;
}

@media (max-width:1000px)
{
  .rwd_main_rihgt_col
  {
    clear: both;
    float: left;

    width: 100%;
    margin: 0px;
  }
}

/*
div.r_box1
{
  position: fixed;
  _position: absolute;
  top: 10px;
  z-index: 999;
}
*/

#topbar
{
  height: 26px;
  background-color: #f6f6f6;
  border-bottom: 2px solid #fff;
}

.aboutus
{
  margin: 0 5px;
}

.useful_item
{
  position: relative;
  _top: -2px;
  display: inline-block;

  font-size: 16px;
  font-size: 1rem;
  font-weight: bold;
  color: #174c9c;

  background-color: #e4e4e4;
}

.useful_item a
{
  color: #174c9c;
}

.useful_item h4
{
  /*
  position: relative !important;
  */

  left: 0 !important;
  display: inline-block;
  cursor: default;
  color: #174c9c;

  font-size: 16px;
  font-size: 1rem;
  font-weight: bold;

  letter-spacing: 1px;
  padding-right: 10px;

  /*
  margin-right: 10px;
  border-right: 1px solid #000;
  */
}

.useful_item h4:after
{
  content: "";
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
  width: 7px;
  height: 7px;
  overflow: hidden;
  background: url(/staticelement/img/main/ico.png) no-repeat 0 -819px;
}

.useful_item ul
{
  position: absolute;
  top: -166px;
  top: -moz-calc(-164px);
  /*
  top: 1em;
  top: -moz-calc(1em);
  */
  left: 25px;
  display: none;
  padding: 0 !important;
  background: white;
  border: 1px solid #f4f4f4;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
}

#topbar ul
{
  float: left;
  margin: 4px 0 0 8px;
  _margin-top: 5px;
}

.useful_item ul li
{
  position: static !important;
  float: none !important;
  display: inline !important;
  margin-right: 0 !important;
}

.useful_item ul li a
{
  display: block;
  padding: 5px 52px 5px 12px;
  height: 1%;
  color: #666 !important;

  font-size: 16px;
  font-size: 1rem;
  font-weight: normal;

  white-space: nowrap;
  letter-spacing: 0 !important;
  cursor: pointer;
}

.useful_item ul li a:hover
{
  background-color: #f6f6f6;
}

#header
{
  height: 142px;
  background: url('/staticelement/img/main/header_bg.png') top repeat-x;
}

/* rwd header */

#rwd_header
{
  height: 84px;

  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 100;
}

#rwd_header .header_blue
{
  background-color: #164b9b;
}

#rwd_header .header_darkblue
{
  background-color: #10408a;

  display: none;
}

#rwd_header_prog .header_darkblue
{
  background-color: #e0e0e0;

  display: none;
}

#rwd_header .header_white
{
  background-color: #f4f4f4;
}

#rwd_header .header_gray
{
  background-color: #dddcec;
  height: 1px;
}

#rwd_header .header_top_bar, #rwd_header_prog .header_top_bar
{
  height: 26px;

  overflow: hidden;

  display: none;
}

.header_top_bar .image_area
{
  float: right;
}

.header_top_bar .image_area a
{
  background-image: url(/staticelement/img/main/social_ico_20px.png);
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  margin: 3px 3px;
  display: inline-block;
  vertical-align: bottom;
}

.header_top_bar .image_area a.facebook_ico
{
}

.header_top_bar .image_area a.googleplus_ico
{
  background-position: -20px 0;
  background-position: -20px 0\0;
}

.header_top_bar .image_area a.youtube_ico
{
  background-position: -40px 0;
  background-position: -40px 0\0;
}

.header_top_bar .image_area a.line_ico
{
  background-position: -60px 0;
  background-position: -60px 0\0;
}

.header_top_bar .image_area a.app_ico
{
  background-position: -80px 0;
  background-position: -80px 0\0;
}

.header_top_bar .image_area a.safechat_ico
{
  background-position: -100px 0;
  background-position: -100px 0\0;
}

.header_top_bar .image_area a.gjw_ico
{
  background-position: -120px 0;
  background-position: -120px 0\0;
}

.header_top_bar .image_area a.youmaker_ico
{
  background-position: -120px 0;
  background-position: -120px 0\0;
}

.header_top_bar .link_area
{
  color: white;

  font-size: 14.4px;
  font-size: 0.9rem;
  line-height: 26px;

  float: right;
}

.header_top_bar .link_area a, .header_top_bar .link_area a:active
{
  color: white;
  padding: 0px 5px;
}

#rwd_header_prog .header_top_bar .link_area
{
  color: #9e9e9e;
}

#rwd_header_prog .header_top_bar .link_area a, #rwd_header_prog .header_top_bar .link_area a:active
{
  color: #9e9e9e;
}

#rwd_header #headerMenu
{
  position: relative;
  top: 15px;
}

#rwd_header #sub_nav
{
  margin-top: 3px;
  float: right;
}

#rwd_header #date_info
{
  float: right;
}

#rwd_header_prog
{
  height: 51px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 100;

  background: #fff;
  border-bottom: 1px #eee solid;
}

#rwd_header_prog #headerMenu
{
  position: relative;
  top: 13px;
}

#rwd_header_prog #sub_nav
{
  margin-top: 5px;
  float: right;
}

#rwd_header_prog #date_info
{
  margin-top: 5px;
}

@media (min-width: 830px)
{
  #rwd_header
  {
    height: 110px;
  }

  #rwd_header_prog
  {
    height: 77px;
  }

  #rwd_header .header_top_bar, #rwd_header_prog .header_top_bar
  {
    display: block;
  }
}

/* ori header */

#logo
{
  width: 350px;
  height: 61px;
  display: block;
  float: left;
  margin-top: 6px;
}

#logo_02
{
  width: auto;
  height: 45px;
  display: block;
  float: left;
  margin-top: 5px;
}

.header_logo
{
  display: none;
  width: 208px;
  height: 40px;
}

.header_logo_min
{
  display: block;
  width: 190px;
  height: 40px;
}

#head_box1
{
  width: 295px;
  float: right;
  margin-top: 15px;
  margin-right: 5px;

  display: none;
}

#head_search
{
  width: 295px;
  float: right;
  margin-bottom: 8px;

  display: none;
}

#head_search input.focus
{
  outline: 0;
  color: #ccc;
}

#rwd_header_prog #head_search input.focus
{
  color: #919191;
}

#head_search input
{
  float: left;
  padding: 2px 5px 2px 10px;
  height: 18px;
  color: #919191;
  font-size:  0.9em;
  border: 0px solid #ddd;
  border-right: none;
  background-color: #10408a;
  -moz-box-shadow: inset 3px 3px 3px rgba(20%,20%,40%,0.5);
  -webkit-box-shadow: inset 3px 3px 3px rgba(20%,20%,40%,0.5);
  box-shadow:inset 3px 3px 3px rgba(20%,20%,40%,0.5);
  border-radius: 3px;

  display: none;
}

#rwd_header_prog #head_search input
{
  border: 1px solid #ddd;
  background-color: #fff;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 5px;
}

/* search bar responsive */

@media (min-width: 510px)
{
  #head_box1
  {
    width: auto;
    display: block;
  }

  #head_search
  {
    width: 190px;
    float: right;
    display: block;
  }

  #head_search input
  {
    width: 140px;
    display: block;
  }

  .header_logo
  {
    display: block;
  }

  .header_logo_min
  {
    display: none;
  }
}

@media (min-width: 900px)
{
  #logo_02
  {
    margin-top: 6px;
    width: 292px;
  }
}

@media (min-width: 860px) and (max-width: 900px)
{
  #logo_02
  {
    width: 255px;/*heree change for "Topic" menu*/
  }
}

#head_search button
{
  float: right;
  width: 30px;
  height: 22px;
  overflow: hidden;
  color: white;
  font-size:  0.95em;
  background: #3e80e9;
  border: none;
  cursor: pointer;
  background: url(/staticelement/img/main/search_ico_04.png) right no-repeat;
}

#rwd_header_prog #head_search button
{
  background: url(/staticelement/img/main/search_ico_03.png) right no-repeat;
}

#head_search button:content
{
  display: none;
}

ul#sub_nav
{
  color: #707070;
  height: 27px;
  *height: 23px !important;
}

ul#sub_nav li
{
  float: left;
  color: #707070;
}

ul#sub_nav li a
{
  padding-right: 9px;
  display: block;
  font-size: 0.95em;
  color: #707070;
}

ul#sub_nav li a:hover
{
  color: #0060b9;
}

ul#sub_nav li.last a
{
  padding-right: 0;
}

ul#sub_nav .color_red
{
  color: #af1513;
}

.text_gallery
{
  width: 410px;
  height: 21px;
  line-height: 21px;
  overflow: hidden;
}

.text_gallery li
{
  list-style: none;
  height: 21px;
}

.text_gallery li a
{
  color: #58433b;
  font-size: 0.95em;
  display: block;
  width: 355px;
  float: left;
  overflow: hidden;
}

.text_gallery li a:hover
{
  text-decoration: underline;
}

#headerDown
{
  margin-top: 4px;
  *margin-top: 0px !important;
  height: 38px;
}

@-moz-document url-prefix()
{
  #headerDown
  {
    margin-top: 2px;
  }
}

#headerMenu ul.nav li a
{
  font-size: 0.9em;
}

#headerMenu li a:hover
{
  color: #5897f6;
  background-color: #fff;
  -moz-box-shadow: outset 1px 1px 1px rgba(20%,20%,40%,0.5);
  -webkit-box-shadow: outset 1px 1px 1px rgba(20%,20%,40%,0.5);
  border-radius: 2px;
}

#headerMenu li.s2 a:hover
{
  color: #0651c6;
  background-color: #0f3b78;
}

#headerMenu li.fix a:hover
{
  color: #e96e35;
  cursor: pointer;
}

#headerMenu li.fix2 a:hover
{
  color: #00a5ab;
}

#headerMenu li.fix
{
  margin-right: 16px;
}

#headerMenu li a
{
  float: left;
  width: 46px;
  height: 35px;
  height: 35px\9;
  font-size: 16px;
  color: #f0f4f9;

  text-align: center;
  display: block;
  padding-top: 2px;
  position: relative;
}

#headerMenu.prog li a
{
  color: #9e9e9e;
}

#headerMenu .news_list_01 li a, #headerMenu ul.newsCategory li a
{
  position: inherit;
  top: 0;
}

#headerMenu li a.more_menu
{
  background: url("/staticelement/img/main/selectall_2_ico.png" ) no-repeat center 23%;
}

#headerMenu.prog li a.more_menu
{
  background: url("/staticelement/img/main/selectall_02_ico.png" ) no-repeat center 23%;
}

#headerMenu li a.more_menu:hover
{
  background: url("/staticelement/img/main/selectall_3_ico.png" ) #fff no-repeat center 23%;
}

#headerMenu li.s2 a
{
  width: 94px;
}

a, a:active
{
  color: black;
}

#broadcast
{
  height: 32px;
}

/* dropdown */

#headerMenu .dropdown
{
  display: none;
  position: absolute;
  /*top: 38px;*/
  top: 36px;
  left: -360px;
  padding: 16px 10px 6px 10px;
  width: 970px;
  height: 200px;
  zoom: 1;
  z-index: 1000;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.45);
}

#headerMenu .dropdown .newsCategory
{
  float: left;
  width: 140px;
  height: 195px;
  margin-right: 10px;
  border-right: 1px #ddd dashed;
}

#headerMenu li .dropdown .newsCategory li a
{
  font-size: 1em;
  width: 62px;
  background: none;
  color: #434343;
  font-weight: bold;
  height: auto;
  line-height: 1.35;
  margin: 0;
  margin-bottom: 10px;
}

#headerMenu li.fix .dropdown .newsCategory li a:hover
{
  text-decoration: underline;
}

#headerMenu .dropdown .lastnews
{
  width: 512px;
  height: 195px;
  float: left;
  padding-right: 3px;
  border-right: 1px #ddd dashed;
}

#headerMenu .dropdown .topnews
{
  float: right;
  width: 285px;
  height: 195px;
  margin: 0 8px;
  overflow: hidden;
}

#headerMenu .dropdown h3
{
  font-size: 0.95em;
  padding: 0 6px;
}

#headerMenu li.fix .dropdown .lastnews .item a.fig_01
{
  float: none;
  display: block;
  overflow: hidden;
  height: 106px;
  width: 156px;
  margin-bottom: 4px;
}

#headerMenu li.fix .dropdown .lastnews .item
{
  float: left;
  padding: 6px;
  width: 156px;
}

#headerMenu li.fix .dropdown .lastnews .item a.fig_01 img
{
  text-align: center;
  height: 106px;
}

#headerMenu li.fix .dropdown .lastnews .item a.txt
{
  float: none;
  display: block;
  width: auto;
  background: none;
  color: black;
  line-height: 1.3em;
  font-size: 14px;
  text-align: left;
}

#headerMenu li.fix .dropdown .lastnews .item:hover a.txt
{
  color: #0060b9;
}

#headerMenu li.fix .dropdown a.img_l_01
{
  display: block;
  overflow: hidden;
  float: left;
  width: 77px;
  height: 77px;
  text-align: center;
  margin: 10px 10px 15px 8px;
}

#headerMenu li.fix .dropdown h3.title_01
{
  border-bottom: 0;
  margin-top: 10px;
}

#headerMenu li.fix .dropdown h3.title_01 a, #headerMenu li.fix .dropdown ul.news_list_01 li a
{
  color: #174c9c;
  width: auto;
  background: none;
  float: none;
  text-align: left;
  line-height: 1.4em;
  display: inline;
  font-weight: normal;
}

#headerMenu li.fix .dropdown ul.news_list_01
{
  margin-left: 6px;
}

#headerMenu li.fix .dropdown a.img_l_01 img
{
  height: 77px;
  margin-left: -19px;
  text-align: center;
}

#headerMenu li.fix .selectall
{
  /*top: 38px;*/
  left: -604px;
  width: 920px;
  padding: 20px 35px;
}

#headerMenu li .selectall div
{
  height: 40px;
}

#headerMenu li .selectall a
{
  width: auto;
  margin-right: 30px;
  color: #696969;
}

#headerMenu li .selectall div.friendweb a
{
  margin-right: 15px;
}

#headerMenu li.fix .program
{
  left: -422px;
  width: 930px;
  height: 500px;
  padding: 20px 30px;
  overflow: auto;
}

#headerMenu li.fix .topic
{
  left: -422px;
  width: 930px;
  height: 500px;
  padding: 20px 30px;
  overflow: auto;
}

#headerMenu li.fix .program .section
{
  display: inline-block;
  vertical-align: top;
  margin-bottom: 05px;
  padding-top: 14px;
  text-align: left;
}

#headerMenu li.fix .program .section ul
{
  width: 120px;
  border-right: #eeeeee solid 1px;
  padding-top: 6px;
  float: left;
  display: inline-block;
  top: 0;
  margin: 5px 5px 14px 0;
}

#headerMenu li.fix .program .section h4
{
  color: #154a9a;
}

#headerMenu li.fix .program .section ul li
{
  float: none;
  margin-bottom: 12px;
  /*
  padding: 0 10px;
  */
}

#headerMenu li.fix .program .section ul li a
{
  color: #222;
  font-weight: 300;
  width: 115px;
  height: auto;
  margin-bottom: 5px;
  float: left;
  display: block;
  padding-top: 2px;
  padding-left: 0px;
  padding-right: 5px;
  text-align: left;
}

#headerMenu li.fix .program .section ul li a:hover
{
  text-decoration: underline;
}

#headerMenu li.fix .program .section ul li a img
{
  width: 111px;
  height: 73px;
}

@media (max-width: 860px)
{
  #headerMenu
  {
    display: none;
  }
}

/* main_left_center_col */

.main_left_center_col
{
  float: left;
  width: 660px;
  margin: 10px 0 10px;
}

.rwd_main_left_center_col
{
  float: left;
  max-width: 660px;
  margin: 10px 0 10px;
}

@media (max-width: 1000px)
{
  .rwd_main_left_center_col
  {
    max-width: none;
    width: 100%;
  }
}

/* new left_center_col */

.pg_main_wrapper
{
  position: relative;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@media (min-width: 960px)
{
  .pg_main_wrapper
  {
    padding-right: 320px;
  }
}

.pg_main_wrapper .pg_main_right
{
  overflow: hidden;
}

@media (min-width: 960px)
{
  .pg_main_wrapper .pg_main_left
  {
    float: left;
    width: 100%;
  }

  .pg_main_wrapper .pg_main_right
  {
    float: right;
    margin-right: -320px;
    width: 300px;
  }
}

/* live page */

.pg_live_wrapper
{
  position: relative;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@media (min-width: 830px)
{
  .pg_live_wrapper
  {
    padding-right: 260px;
  }
}

.pg_live_wrapper .pg_live_right
{
  overflow: hidden;
}

@media (min-width: 830px)
{
  .pg_live_wrapper .pg_live_left
  {
    float: left;
    width: 100%;
  }

  .pg_live_wrapper .pg_live_right
  {
    float: right;
    margin-right: -260px;
    width: 260px;
  }
}

/* broadcast */

@-moz-document url-prefix()
{
  .broadcast_ico
  {
    margin-top: 4px;
  }
}

.gallery
{
  padding-top: 5px;
  padding-top: 4px\9;
  padding-left: 10px;
  width: 460px;
  overflow: hidden;
}

.rwd_gallery
{
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 10px;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  overflow: hidden;
  height: 32px;
}

.l_gallery
{
  float: left;

  width: 100%;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  display: none;
}

.r_gallery
{
  width: 100%;
  float: right;
}

.broadcast_ico
{
  width: 23px;
  margin-top: 7px;
  height: 14px;
  float: left;
  background: url(/staticelement/img/main/broadcast_ico.png) no-repeat;
}

.main_marquee
{
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  line-height: 28px;

  overflow: hidden;
  /*
  line-height: 26px;
  margin-top: 1px;
  */
}

.main_marquee, .main_marquee a, .main_marquee a:hover, .main_marquee a:active
{
  font-size: 0.95rem;
  font-size: 15.2px;

  color: #58433b;
}

.main_marquee a:hover
{
  padding-bottom: 0.25px;
  border-bottom: 1px solid;
}

#date_info
{
  color: #b93232;
  width: auto;
  font-size: 0.95rem;
  font-size: 15.2px;
  line-height: 28px;
  text-align: right;

  display: none;
}

@media (min-width: 450px)
{
  .rwd_gallery
  {
    padding-right: 200px;
  }

  .l_gallery
  {
    display: block;
  }

  .r_gallery
  {
    margin-right: -200px;

    width: 200px;
  }

  #date_info
  {
    display: block;
  }
}

@media (min-width: 300px) and (max-width: 449px)
{
  .l_gallery
  {
    display: block;
  }
}

/* content */

#content
{
  clear: both;
}

#real_time_news
{
  width: 982px;
  height: 42px;
  color: #c41f01;
  font-size: 1em;
  font-weight: bold;
  padding: 12px 10px 0 20px;
  background: url(/staticelement/img/main/Real_time_news_bg.jpg) no-repeat;
}

#real_time_news .realtime
{
  margin-right: 40px;
  font-size: 1.5em;
  color: #ffeea9;
  font-weight: bold;
}

#real_time_news .close_01
{
  float: right;
  margin-right: 35px;
  cursor: pointer;
  display: block;
  width: 27px;
  height: 27px;
  background: url(/staticelement/img/main/close_bg.jpg) no-repeat;
}

#nav_list
{
  height: 28px;
  color: #a3a3a3;
  width: 650px;
  margin-left: 30px;
  margin-top: 5px;
}

#nav_list a
{
  font-size: 0.9em;
  color: #a3a3a3;
}

#nav_list a.index
{
  color: #458cfe;
}

.block_head_title
{
  padding: 5px 0 5px 35px;
  background: #164b9b;
  line-height: 1.35;
}

.block_head_title2
{
  padding: 5px 0 5px 35px;
  background: #f76431;
  color: #ffffff;
}

.block_head_title h3
{
  color: #fff;
}

.prog_head_title
{
  padding: 5px 0 5px 35px;
  background: #f65f32;
  line-height: 1.35;
}

.prog_head_title h3
{
  color: #fff;
}

/* new message title */
.new_message_title
{
  padding: 5px 0 7px 0;
  background: #f65f32;
  line-height: 1.35;
}

.new_message_title h3,.new_message_title h4
{
  color: #fff;
}

.new_message_title span:first-child {
  float: left;
  width: 68%;
  margin-left: 35px;
}

@media (min-width: 450px) and (max-width: 680px) {
  .new_message_title span:first-child
  {
    width:75%;
  }
}

@media (min-width: 680px) and (max-width: 959px) {
  .new_message_title span:first-child
  {
    width:83%;
  }
}

@media (min-width: 960px)
{
  .new_message_title span:first-child
  {
    width:88%;
  }
}

.rec_head_title
{
  padding: 5px 0 5px 35px;
  background: #f63284;
  line-height: 1.35;
}

.rec_head_title h3
{
  color: #fff;
}

.more_link
{
  display: block;
  color: #aeaeae;
  font-size: 1rem;
  font-size: 16px;
  text-align: right;
  margin: 0 0 10px 0;
}

.more_link:hover
{
  text-decoration: underline;
}

/* footer css */

#footer
{
  margin-top: 20px;
  min-width: 1000px;
  background-color: #eaeaea;
}

#footer_top
{
  height: 40px;
  background-color: #666666;
  border-bottom: 1px solid #d0d0d0;
  border-top: 1px solid #e1e1e1;
}

#footer_top_con
{
  margin: 0 auto;
  width: 980px;
  /*
  margin-top: 3px;
  */
  font-size: 14px;
  color: #ffffff;
}

.footer_top_block
{
  margin-top: 9px;
  width: 140px;
  font-size: 18.6667px;
  display: inline;
}

.footer_top_block div
{
  padding-top: 8px;
}

.footer_top_block a
{
  font-size: 16px;
  color: #ffffff;
}

.footer_middle_block
{
  margin-top: 9px;
  width: 140px;
  font-size: 18.6667px;
  display: inline;
}

.footer_middle_block div
{
  padding-top: 8px;
}

.footer_middle_block a
{
  font-size: 16px;
  color: #999999;
}

#footer_middle_con
{
  margin: 0 auto;
  width: 980px;
  color: #ffffff;
  min-height: 430px;
}

#footer #footerInner
{
  width: 1000px;
  padding-top: 45px;
  *padding-top: 60px;
  margin-left: 0px;
}

.footer_botton
{
  margin-top: 17px;
  border-top:1px solid #d6d6d6;
  height: 120px;
}

#footer #bottomLogo
{
  width: 219px;
  height: 31px;
  float: left;
  /*
  background: url( "../images/ntdaptvfooterLogo.jpg" ) no-repeat 0 0;
  */
  *position: relative;
  *top: -15px;
}

#footer #footerMenu
{
  position: relative;
  margin-left: 620px;
}

#footerMenu li
{
  display: inline;
  font-size: 13px;
  /*
  line-height: 13px;
  */
  padding: 0 7px 0px 7px;
  padding: 3px 7px 0 7px \9;
  border-right: solid 1px gray;
}

#footerMenu li a
{
  color: gray;
}

#footerMenu li.firstmenu
{
  border-left: solid 1px gray;
  font-size: 13px;
  /*
  line-height: 13px;
  */
  padding-left: 7px;
}

#theCopyright
{
  line-height: 20px;
  font-size: 13px;
  color: #989898;
}

#theCopyright2
{
  line-height: 20px;
  font-size: 12px;
  position: relative;
  top: -12px;
  color: #cfcfcf;
}

#friendlyLink
{
  position: relative;
  top: -5px;
  padding-left: 645px;
  padding-left: 630px \9;
  *padding-left: 640px;
  width: 265px;
}

#friendlyLink li
{
  font-size: 11px;
  display: inline;
  padding: 0 4px 0 7px;
  padding: 0 4px 0 7px \9;
  border-left: solid 1px gray;
}

#friendlyLink li a
{
  color: gray;
  line-height: 13px;
}

#friendlyLink li.link
{
  border-left: none;
  padding: 0px;
  vertical-align: middle;
}

#friendlyLink li.link a
{
  color: white;
  margin-right: 7px;
  padding: 0 6px 1px 6px;
  *padding: 1px 6px 0px 6px;
  background-color: #f4742d;
  border: outset #d06225 1px;
}

/* Main Class */

.ad_box
{
  padding: 3px 0;
  border: 1px solid #e6e6e6;
  margin: 10px auto;
  text-align: center;
}

#ad_1250
{
  width: 1250px;
}

.hidde
{
  display: none;
}

.ad_box_left
{
  width: 120px;
  height: 600px;
  position: absolute;
  top: 117px;
}

/* 12.1. tab content
---------------------------------------------*/

.content li
{
  height: 64px;
  border-bottom: 1px solid #dedede;
  clear: both;
  padding: 8px 7px;
  list-style-type: none;
}

.content li span
{
  color: #666;
  font-size: 0.95em;
}

.content li:hover
{
  background-color: #f4f4f4;
  color: #064fc3;
}

.content li:hover span
{
  color: #064fc3;
}

.content li img
{
  height: 64px;
  float: left;
  padding-right: 7px;
}

/* right AD */

.ad_right_box > div
{
  margin: 0 auto;
}

.ad_right_box h3
{
  color: #c2c2c2;
  font-size: 1em;
  margin-bottom: 5px;
}

/* article ad */

.NTDAPTVWeb_NewsArticle_ArticlePlatinum
{
  float: none;
}

.NTDAPTVWeb_NewsArticle_ArticlePlatinum > div
{
  margin: 0 auto 15px;
}

.NTDAPTVWeb_Program_TopRightPlatinum
{
  float: none;
}

.NTDAPTVWeb_Program_TopRightPlatinum > div
{
  margin: 0 auto 15px;
}

.NTDAPTVWeb_NewsArticle_ArticleGold_B5,
.NTDAPTVWeb_NewsArticle_ArticleGold_B6,
.NTDAPTVWeb_NewsCategory_MiddlePlatinum_E2,
.NTDAPTVWeb_NewsCategory_MiddlePlatinum_E3,
.NTDAPTVWeb_TopicCategory_LeftBottomGold_P2,
.NTDAPTVWeb_TopicCategory_LeftBottomPlatinum_P3,
.NTDAPTVWeb_ProgramIndex_MiddlePlatinum_H3,
.NTDAPTVWeb_ProgramIndex_MiddlePlatinum_H4,
.NTDAPTVWeb_ProgramCategory_TopRightPlatinum,
.NTDAPTVWeb_ProgramCategory_TopRightRightPlatinum,
.NTDAPTVWeb_Program_ProgramGold_L4,
.NTDAPTVWeb_Program_ProgramGold_L5,
.NTDAPTVWeb_Channel_BottomGold_G3,
.NTDAPTVWeb_Channel_BottomGold_G4
{
  float: left;
  width: 100%;
  min-width: 300px;
}

[data-eq-state="2-column-grid"] .NTDAPTVWeb_NewsArticle_ArticleGold_B5,
[data-eq-state="2-column-grid"] .NTDAPTVWeb_NewsArticle_ArticleGold_B6,
[data-eq-state="2-column-grid"] .NTDAPTVWeb_NewsCategory_MiddlePlatinum_E2,
[data-eq-state="2-column-grid"] .NTDAPTVWeb_NewsCategory_MiddlePlatinum_E3,
[data-eq-state="2-column-grid"] .NTDAPTVWeb_TopicCategory_LeftBottomGold_P2,
[data-eq-state="2-column-grid"] .NTDAPTVWeb_TopicCategory_LeftBottomPlatinum_P3,
[data-eq-state="2-column-grid"] .NTDAPTVWeb_ProgramIndex_MiddlePlatinum_H3,
[data-eq-state="2-column-grid"] .NTDAPTVWeb_ProgramIndex_MiddlePlatinum_H4,
[data-eq-state="2-column-grid"] .NTDAPTVWeb_ProgramCategory_TopRightPlatinum,
[data-eq-state="2-column-grid"] .NTDAPTVWeb_ProgramCategory_TopRightRightPlatinum,
[data-eq-state="2-column-grid"] .NTDAPTVWeb_Program_ProgramGold_L4,
[data-eq-state="2-column-grid"] .NTDAPTVWeb_Program_ProgramGold_L5,
[data-eq-state="2-column-grid"] .NTDAPTVWeb_Channel_BottomGold_G3,
[data-eq-state="2-column-grid"] .NTDAPTVWeb_Channel_BottomGold_G4
{
  width: 50%;
}

.NTDAPTVWeb_NewsArticle_ArticleGold_B5 > div,
.NTDAPTVWeb_NewsArticle_ArticleGold_B6 > div,
.NTDAPTVWeb_NewsIndex_TopRightPlatinum > div,
.NTDAPTVWeb_Program_ProgramGold_L4 > div,
.NTDAPTVWeb_Program_ProgramGold_L5 > div,
.NTDAPTVWeb_Channel_BottomGold_G3 > div,
.NTDAPTVWeb_Channel_BottomGold_G4 > div
{
  margin: 0 auto 15px;
}

.NTDAPTVWeb_NewsIndex_RightHugeGold > div
{
  margin: 15px auto 0;
}

.NTDAPTVWeb_NewsIndex_RightGold
{
  margin-top: 10px;
}

.NTDAPTVWeb_NewsIndex_RightGold h3
{
  color: #c2c2c2;
  font-size: 16px;
  font-size: 1rem;
}

.NTDAPTVWeb_NewsIndex_RightGold > div
{
  margin: 0 auto;
}

.NTDAPTVWeb_NewsCategory_MiddleRightPlatinum
{
  margin-top: 10px;
}

.NTDAPTVWeb_NewsCategory_MiddleRightPlatinum > div
{
  margin: 0 auto;
}

.NTDAPTVWeb_TopicCategory_LeftBottomGold_P2 > div,
.NTDAPTVWeb_TopicCategory_LeftBottomPlatinum_P3 > div
{
  margin: 0 auto;
  padding: 11px 0;
}

.NTDAPTVWeb_NewsCategory_MiddlePlatinum_E2 > div,
.NTDAPTVWeb_NewsCategory_MiddlePlatinum_E3 > div
{
  margin: 0 auto;
  padding: 11px 0;
}

.NTDAPTVWeb_ProgramIndex_MiddlePlatinum_H3 > div,
.NTDAPTVWeb_ProgramIndex_MiddlePlatinum_H4 > div
{
  margin: 0 auto 15px;
}

.NTDAPTVWeb_ProgramCategory_TopRightPlatinum > div,
.NTDAPTVWeb_ProgramCategory_TopRightRightPlatinum > div
{
  margin: 10px auto 0;
}

[data-eq-state="2-column-grid"] .NTDAPTVWeb_ProgramCategory_TopRightPlatinum > div,
[data-eq-state="2-column-grid"] .NTDAPTVWeb_ProgramCategory_TopRightRightPlatinum > div
{
  margin: 10px auto;
}

.NTDAPTVWeb_Channel_TopRightPlatinum > div
{
  margin: 0 auto 10px;
}

/*
.adslot__holder
{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: hsla(0,0%,78%,.5);
  text-align: center;
  z-index: -1;
}

.adslot__holder:before
{
  content: " ";
  display: inline-block;
  height: 100%;
  width: 0%;
  vertical-align: middle;
}

.adslot__holder:after
{
  content: '廣告';
  background-color: rgba(0,0,0,.5);
  border-radius: 2px;
  color: #fff;
  font-size: 16px;
  line-height: 16px;
  padding: 2px 4px;
}

.adslot-300x250
{
  width: 300px;
  height: 250px;
}

.NTDAPTVWeb_Index_RightTopPlatinum
{
  position: relative;
}
*/

.NTDAPTVWeb_Index_RightTopPlatinum > div
{
  margin: 0 auto 10px;
}

.NTDAPTVWeb_Index_RightHugeGold > div
{
  margin: 10px auto 0px;
}

.NTDAPTVWeb_Index_RightGold > div
{
  margin: 10px auto 0px;
}

@media (min-width: 450px)
{
  .NTDAPTVWeb_NewsArticle_ArticlePlatinum
  {
    float: left;
  }

  .NTDAPTVWeb_NewsArticle_ArticlePlatinum > div
  {
    margin: 0 25px 25px 0;
  }

  .NTDAPTVWeb_Program_TopRightPlatinum
  {
    float: right;
  }

  .NTDAPTVWeb_Program_TopRightPlatinum > div
  {
    margin: 0 0 25px 25px;
  }

  .NTDAPTVWeb_TopicCategory_LeftBottomGold_P2 > div,
  .NTDAPTVWeb_TopicCategory_LeftBottomPlatinum_P3 > div
  {
    padding: 15px 0;
  }

  .NTDAPTVWeb_NewsCategory_MiddlePlatinum_E2 > div,
  .NTDAPTVWeb_NewsCategory_MiddlePlatinum_E3 > div
  {
    padding: 15px 0;
  }
}

@media (max-width: 959px)
{
  .NTDAPTVWeb_NewsIndex_TopRightPlatinum
  {
    display: none;
  }
}

@media (max-width: 1000px)
{
  .NTDAPTVWeb_Index_MiddleHugeGold
  {
    display: none;
  }
  
  .NTDAPTVWeb_Index_RightHugeGold > div
  {
    margin-top: 0px;
  }
}

/* video box */

.video_item
{
  float: left;
  width: 140px;
  padding: 0 5px 8px 5px;
}

.video_item .vio_ico
{
  position: absolute;
  display: block;
  width: 141px;
  height: 131px;
  z-index: 2;
  background: url(/staticelement/img/main/ico_video_play.png) left top no-repeat;
}

.video_item .vio_ico:hover
{
  background: url(/staticelement/img/main/ico_video_play_01.png) left top no-repeat;
}

.video_item img
{
  position: relative;
  top: 1px;
  left: 1px;
  z-index: 1;
  width: 140px;
}

.video_item a.vio_title
{
  color: #666;
  font-size: 0.95em;
}

/* news_box_01 */

.news_box_01
{
  float: left;
  width: 310px;
  padding: 5px 15px 5px 5px;
  margin-top: 10px;
}

.title_01
{
  padding: 3px 0;
  color: #064fc3;
  font-size: 1em;
  border-bottom: 1px solid #dedede;
}

.news_box_01 .related_news_list
{
  padding: 5px 10px;
}

.related_news_list ul
{
  list-style-position: inside;
}

.related_news_list ul li
{
  color: #003f84;
  line-height: 1.8em;
}

.related_news_list ul li a
{
  color: #666;
  font-size: 0.95em;
}

.related_news
{
  float: none;
  margin-top: 7px;
}

.related_news li a
{
  color: #174c9c;
}

.news_list_01 li a
{
  color: #174c9c;
}

.related_news li a:hover , .news_list_01 li a:hover
{
  color: #cc1a18;
}

/* news_box_02 */

.news_box_02 .item_02, .news_box_top .item_02
{
  clear: both;
  height: 80px;
  padding: 10px 0;
}

.news_box_02 .item_02:nth-child(1)
{
  border-bottom: 1px dotted #ddd;
}

.news_box_02 > h3
{
  height: 25px;

  margin-top: 10px;
  margin-bottom: 10px;

  overflow: hidden;
}

.news_box_top h3
{
  height: 25px;

  margin-top: 10px;
  margin-bottom: 10px;

  overflow: hidden;
}

.news_box_02 h3 a, .news_box_top h3 a
{
  font-size: 0.9em;
  color: #174c9c;
}

.news_box_02 .item_02 h3 a, .news_box_top .item_02 h3 a
{
  color: #7c7c7c;
}

.news_box_02 .item_02:hover h3 a, .news_box_top .item_02:hover h3 a
{
  color: #174c9c;
}

.news_box_02 a.img_r_01, .news_box_top a.img_r_01
{
  display: block;
  overflow: hidden;
  float: right;
  width: 77px;
  height: 77px;

  text-align: center;
  margin: 0 0 10px 10px;
}

.news_box_02 a.img_l_01, .news_box_top a.img_l_01
{
  display: block;
  overflow: hidden;
  float: left;
  width: 80px;
  height: 80px;

  text-align: center;
  margin:0 10px 0px 0;
}

.news_box_02 a.img_r_01 img, .news_box_top a.img_r_01 img
{
  height: 77px;
  margin-left: -19px;
  text-align: center;
}

.news_box_02 a.img_l_01 img, .news_box_top a.img_l_01 img
{
  height: 80px;
  margin-left: -19px;
  text-align: center;
}

.news_box_02 .article_time, .news_box_top .article_time
{
  height: 20px;
}

.news_box_02 .article_time a, .news_box_top .article_time a
{
  color: #737373;

  font-size: 12px;
  line-height: 1.35;
  vertical-align: top;
}

.news_box_02 p a, .news_box_top p a
{
  font-size: 0.875rem;
  font-size: 14px;
  display: block;
  height: 60px;
  line-height: 20px;
  overflow: hidden;
  color: #828282;
}

.news_box_02 .related_news, .news_box_top .related_news
{
  margin: 10px 0 0px 0;
  padding-bottom: 10px;
  border-bottom: 1px dashed #ddd;
}

.news_box_02 .news_list_01
{
  clear: left;
  margin: 15px 0 0 0;
  padding: 10px 0 10px 0;
  border-top: 1px dashed #ddd;
}

#v2014_importantNews .news_list_01
{
  clear: left;
  margin: 10px 0 0 0;
  padding: 10px 0 10px 0;
  border-top: 1px dashed #ddd;
}

#facebook_recommand
{
  margin-top: 15px;
  border-top: 1px solid #dedede;
}

/* vdieo_scrollable */

.vdieo_scrollable
{
  position: relative;
  z-index: 1;
  margin-top: 10px;
  height: 235px;
}

.scrollable_box
{
  position: relative;
  margin: 0 auto;
  height: 200px;
  border: 1px solid #e6e6e6;
}

/* breadcrumbs */

.breadcrumbs
{
  margin-top: 10px;
}

.breadcrumbs ol
{
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;

  font-size: 1.125rem;
  font-size: 18px;

  color: #7c7c7c;
}

.breadcrumbs li
{
  display: inline;
  padding: 0 5px;
}

.breadcrumbs li:first-child
{
  padding: 0 5px 0 0;
}

.breadcrumbs li a
{
  color: #7c7c7c;
}

#hotkeywords
{
  margin-top: 10px;
  width: 570px;
  height: 20px;
  float: left;
  overflow: hidden;
}

#hotkeywords span
{
  font-size: 0.9em;
  color: #b01415;
}

#hotkeywords a
{
  margin-right: 10px;
  font-size: 0.9em;
  color: #444444;
  white-space: pre-wrap;
}

#hotkeywords a:hover
{
  color: #0060b9;
}

/* Video_Switch */

#Video_Switch
{
  width: 300px;
  height: 380px;
  margin-top: 10px;
}

#Video_Switch h3
{
  color: #0753ca;
  font-size: 1em;
  margin-bottom: 5px;
}

#Video_Switch .panes > div
{
  border: 1px solid #ddd;
  padding: 8px;
  height: 80px;
  margin: 3px 0 4px 0;
  overflow: hidden;
}

#Video_Switch .panes > div h3
{
  font-size: 0.95em;
}

#Video_Switch .panes > div p
{
  font-size: 0.9em;
  text-align: justify;
  text-justify: inter-ideograph;
}

#tabs02 li
{
  display: block;
  width: 73px;
  height: 50px;
  float: left;
  margin: 0 1px;
}

#tabs02 li a
{
  position: relative;
  display: block;
  width: 73px;
  height: 50px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}

#tabs02 li a img
{
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 70px;
}

#tabs02 li a span
{
  display: block;
  position: absolute;
  *position: relative;
  top: 25px;
  left: 0;
  *left: -1px;
  z-index: 10;
  width: 70px;
  height: 15px;
  font-size: 13px;
  color: white;
  padding: 2px 0;
  background-color: rgba(0, 0, 0, 0.6);
  background-color: #555 \9;
  *background-color: #555;
}

#tabs02 li.current a span
{
  color: #ffa904;
}

#tabs02 li a .pointer
{
  position: absolute;
  top: -5px;
  left: 23px;
  width: 25px;
  height: 12px;
  background: url(/staticelement/img/main/current.png) no-repeat 0 0;
  overflow: hidden;
  z-index: 100;
  display: none;
}

#tabs02 li.current a .pointer
{
  display: block;
}

.box_01
{
  height: 260px;
  overflow: hidden;
}

.box_01 h3.news_category a
{
  font-size: 1em;
  color: white;
  margin: 3px 0 10px 0;
}

.v2014_video-boxes ul.tabs li a
{
  background-color: white;
  color: #000;
}

.v2014_video-boxes ul.tabs li a.current, .v2014_video-boxes ul.tabs li a:hover
{
  background-color: #164b9b;
  color: white;
}

/*  9ping_box  */

#sprite-9ping
{
  background-color: #fff;
  border: 1px solid #ddd;
  border-right: 0;
  border-left: 0;
  padding: 10px 12px;
  margin-top: 10px;
}

#sprite-9ping div img, #sprite-9ping div
{
  height: 50px;
  vertical-align: middle;
}

#sprite-9ping div img
{
  margin-right: 10px;
}

#abort_number
{
  color: #fc7e43;
  font-size: 1.5em;
}

.go_tuidang
{
  margin-left: 20px;
  background-color: #3f7fe9;
  color: white;
  padding: 3px 15px;
}

/* lastnews_box */

a.img_l_02
{
  display: block;
  overflow: hidden;
  float: left;
  width: 60px;
  height: 60px;
  text-align: center;
  margin: 0 15px 10px 0;
}

.ul_style_02
{
  list-style: none;
}

.ul_style_02 li
{
  position: relative;
  margin-bottom: 6px;
  padding-right: 10px;
  overflow: hidden;
  zoom: 1;
  color: #0354c9;
}

.ul_style_02 li a
{
  font-size: 0.85em;
  line-height: 1.35;
  text-decoration: none;
  font-weight: normal;
  color: #0354c9;
}

.ul_style_02 a.img_l_02 img
{
  height: 60px;
  margin-left: -15px;
  text-align: center;
}

#social_box
{
  height: 87px;
  border-top: 2px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding-top: 10px;
}

#social_box h3
{
  color: #000;
  padding-bottom: 5px;
  padding-left: 12px;
}

#social_box a
{
  background-image: url(/staticelement/img/main/social_ico2.jpg);
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  float: left;
  margin: 10px 6px 0 10px;
  position: relative;
}

#social_box a.youtube_ico
{
  background-position:-100px 0;
  background-position:-100px 0\0;

  display: block;
}

#social_box a.facebook_ico
{
  display: block;
}

#social_box a.google_ico
{
  background-position:-50px 0;
  background-position:-50px 0\0;

  display: block;
}

#social_box a.twitter_ico
{
  background-position:-25px 0;
  background-position:-25px 0\0;

  display: block;
}

#social_box a.email_ico
{
  background-position:-75px 0;
  background-position:-75px 0\0;

  display: block;
}

#social_box a.line_ico
{
  background-position:-125px 0;
  background-position:-125px 0\0;

  display: block;
}

#social_box a.app_ico
{
  background-position:-150px 0;
  background-position:-150px 0\0;

  display: block;
}

#social_box a.safechat_ico
{
  background-position:-175px 0;
  background-position:-175px 0\0;

  display: block;
}

#social_box a.gjw_ico
{
  background-position:-200px 0;
  background-position:-200px 0\0;

  display: block;
}

#social_box a.youmaker_ico
{
  background-position:-200px 0;
  background-position:-200px 0\0;

  display: block;
}

#social_box .ios a.line_ico,
#social_box .android a.line_ico,
#social_box .iemobile a.line_ico
{
  display: block;
}

#social_box a:hover
{
  top: 1px;
  left: 1px;
}

a.facebook_ico:hover
{
}

a.google_ico:hover
{
}

a.twitter_ico:hover
{
}

a.email_ico:hover
{
}

/* tag_list */

.tag_list
{
  padding-left: 60px;
  background: url(/staticelement/img/main/tag_ico.jpg) no-repeat;
  color: #939393;
  margin-bottom: 15px;
}

.tag_list a
{
  margin: 0 16px;
  font-size: 1.125rem;
  font-size: 18px;
  line-height: 1.75;
  color: #939393;
  
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* this is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* instead use this non-standard one: */
  word-break: break-word;
}

.tag_list a:hover
{
  color: #0753ca;
}

.embed_code
{
  display: none;
  clear: left;
  padding: 15px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  position: relative;
  z-index: 100;
  top: -5px;
}

/* tag_list */

.fb_like_btn
{
  margin: 0 0 15px 0;
}

.fb_like_btn span,
.fb_like_btn a
{
  vertical-align: top;
  font-size: 1em;
  line-height: 20px;
}

.fb_like_btn .fb_page_name
{
  color: #4080ff;
}

/* topic_list */

#TopicList
{
  margin-top: 20px;
}

#TopicList h3
{
  color: #848484;
  font-size: 1em;
  margin: 5px 0;
}

.topic_item h3 a
{
  line-height: 1.3em;
}

.topic_item
{
  height: auto;
  padding-bottom: 8px;
  margin-bottom: 15px;
  border-bottom: 1px solid #ddd;
}

.topic_item a.img_02
{
  display: block;
  overflow: hidden;
  width: 300px;
  height: 168px;
  position: relative;
}

.topic_item a.vid-mark:after
{
  content: "";
  position: absolute;
  bottom: 6px;
  left: 6px;
  width: 28px;
  height: 28px;
  background: url(/staticelement/img/main/play_ico.png) no-repeat;
}

.topic_item img
{
  width: 300px;
  margin-top: -16px;
}

.box_title
{
  color: #0754c8;
  margin-bottom: 5px;
}

#topic_box
{
  margin-top: 15px;
  width: 1000px;
  *margin-bottom: 15px;
}

#topic_box .topic_item_01
{
  display: inline-block;
  *display: block;
  *float: left;
  width: 300px;

  overflow: hidden;
  margin: 5px 30px 30px 0;
}

.topic_item_01 a.img_03
{
  display: block;
  width: 300px;
  height: 200px;
  overflow: hidden;
  position: relative;
}

.topic_item_01 img
{
  width: 300px;
}

.topic_item .tag_list
{
  margin-top: 10px;
}

/* safari */

@media screen and (-webkit-min-device-pixel-ratio:0)
{
  .topic_item_01 img
  {
    width: 300px;
    height: 200px;
  }
}

.topic_item_01 .topic_info
{
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 15px 15px 10px 15px;
  width: 270px;
  font-size: 1.2em;
  color: white;
  font-weight: 700;
  background: url(/staticelement/img/main/opacity_bg.png) repeat;
}

.topic_item_01 .topic_desc
{
  display: none;
  font-size: 15px;
  line-height: 1.48em;
  padding-top: 15px;
  height: 185px;
  overflow: hidden;
}

.topic_item_01 .topic_desc h3
{
  border-bottom: 1px solid #ddd;
  margin: 15px 0;
}

/* v2014_category_list */

.v2014_category_list .pane_list
{
  list-style: none;
  clear: left;
  position: relative;
  top: -1px;
  border-bottom: 1px solid #ddd;
}

.v2014_category_list .pane_list li
{
  display: block;
  border-top: 1px solid #ddd;
  position: relative;
  overflow: hidden;
}

.v2014_category_list .pane_list li a.art
{
  display: block;
  line-height: 0;

  padding: 11px 0;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.v2014_category_list .pane_list li a.art.hoverd
{
  background-color: #fafafa;
}

.v2014_category_list .pane_list li a.art.actived
{
  background-color: #eeeeee;
}

.v2014_category_list .pane_list li img
{
  display: block;
  line-height: 0;

  padding-right: 11px;
  overflow: hidden;
  float: left;

  width: 122px;
  height: 81px;
}

.v2014_category_list .pane_list li h3
{
  font-size: 18px;
  font-size: 1.125rem;
  overflow: hidden;
  height: 60px;
  line-height: 20px;
  color: #174c9c;
}

.v2014_category_list .pane_list li .article_time
{
  padding-top: 4px;

  height: 17px;
  color: #737373;
  font-size: 15px;
  line-height: 17px;
}

.v2014_category_list .pane_list li p
{
  font-size: 16px;
  font-size: 1rem;
  overflow: hidden;
  display: none;
  line-height: 24px;
}

.category_list_padding
{
  padding: 10px 0px 0px 0px;
}

@media (min-width: 450px)
{
  .v2014_category_list .pane_list li a.art
  {
    padding: 15px 0;
  }

  .v2014_category_list .pane_list li img
  {
    padding-right: 15px;

    width: 157px;
    height: 105px;
  }

  .v2014_category_list .pane_list li h3
  {
    font-size: 24px;
    font-size: 1.5rem;
    overflow: hidden;
    height: 64px;
    line-height: 32px;
  }

  .v2014_category_list .pane_list li .article_time
  {
    padding-top: 18px;

    height: 23px;

    font-size: 15px;
    line-height: 1.5;
  }
}

@media (min-width: 650px)
{
  .v2014_category_list .pane_list li h3
  {
    font-size: 21px;
    font-size: 1.3125rem;
    height: 29px;
    line-height: 29px;
  }

  .v2014_category_list .pane_list li .article_time
  {
    padding-top: 0px;

    height: 28px;
  }

  .v2014_category_list .pane_list li p
  {
    height: 48px;
    display: block;
  }
}

@media (min-width: 960px)
{
  .category_list_padding
  {
    padding: 5px 0px 0px 0px;
  }
}

.v2014_category_list_pagination
{
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
}

.v2014_category_list_pagination .paginationEnd
{
  border: 1px solid #164b9b;
  border-radius: 5px;
}

.v2014_category_list_pagination a, .v2014_category_list_pagination span
{
  display: inline-block;
  font-size: 18px;
  color: #b3b3b3;
  font-weight: bold;
  padding: 5px;
  min-width: 24px;
  line-height: 24px;
}

.v2014_category_list_pagination a.prev, .v2014_category_list_pagination a.next
{
  color: white;
  border: 1px solid #164b9b;
  background-color: #164b9b;
  border-radius: 5px;
}

.v2014_category_list_pagination span
{
  color: #333;
}

.v2014_category_list_pagination a.page:hover
{
  color: #164b9b;
}

.v2014_category_list_pagination a.dist_far, .v2014_category_list_pagination a.dist_close
{
  display: none;
}

@media (min-width: 450px)
{
  .v2014_category_list_pagination a.dist_close
  {
    display: inline-block;;
  }
}

@media (min-width: 680px)
{
  .v2014_category_list_pagination a.dist_far
  {
    display: inline-block;;
  }
}

#HR_China
{
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
}

.list_02
{
  margin: 10px;
}

.list_02 li
{
  font-size: 0.95em;
  line-height: 2.1em;
  list-style: none;
}

#goTop
{
  /*display: none;*/
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.5s linear, visibility 0.5s linear;
  -moz-transition: opacity 0.5s linear, visibility 0.5s linear;
  -o-transition: opacity 0.5s linear, visibility 0.5s linear;
  transition: opacity 0.5s linear, visibility 0.5s linear;
  width: 50px;
  height: 50px;
  background-color: rgb(216, 216, 216);
  border-radius: 5px;
  box-shadow: 1px 1px #999999;
  /*background: url(/staticelement/img/main/toTop_btn.png) no-repeat;*/
  position: fixed;
  right: 11px;
  /* Tim, 2018-03-20, button should not close to bottom (0~2x pixels),
     because ios safari will show browser bar when touch bottom area
     (verified at iOS 11.2.6). */
  bottom: 31px;
  z-index: 99999;
}

#goTop.goTopShow
{
  visibility: visible;
  opacity: 1;
}

#goTop:before
{
  content: '';
  border: 0px;
  width: 30px;
  height: 3px;
  background-color: #FFFFFF;
  border-style: solid;
  font-size: 0;
  line-height: 0;
  left: 10px;
  top: 10px;
  position: absolute;
}

#goTop:after
{
  content: '';
  border-color: transparent transparent #ffffff transparent;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 15px 26px 15px;
  font-size: 0;
  line-height: 0;
  left: 10px;
  top: 15px;
  position: absolute;
}

/* 左邊固定 news_left_menu */

.news_left_menu
{
  width: 100px;
  margin-right: 20px;
  text-align: right;
  position: fixed;
  top: 185px;
  line-height: 2.1em;
}

.news_left_menu ul li
{
  list-style: none;
}

.news_left_menu ul li a
{
  color: #5c5c5c;
  font-size: 0.95em;
}

.news_left_menu ul.newsCategory li.index a, .news_left_menu ul.newsCategory li a:hover
{
  color: #fff;
  background-color: #5c5c5c;
  padding: 2px;
}

/* 關於我們 */

#menu_left_fix
{
  width: 100%;
  padding: 10px 0;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#menu_left_fix h2
{
  font-size: 1.5rem;
  font-size: 24px;
  width: 100%;
  padding: 10px;
  text-align: center;
  cursor: pointer;

  background: #e3e3e3;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#menu_left_fix .tabs li
{
  list-style: none;
  padding: 10px;
  text-align: center;

  background: #f4f4f4;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  display: none;
}

#menu_left_fix .tabs.pressed li
{
  display: block;
}

#menu_left_fix .tabs li:hover
{
  background: #ffffff;
}

#menu_left_fix .tabs li a
{
  font-size: 1.125rem;
  font-size: 18px;
  width: 100%;
  display: block;
}

#menu_left_fix .tabs li a.current
{
  color: #2179f7;
}

#tab_content
{
  width: 100%;
  padding: 10px 0;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#tab_content h2.title
{
  padding-right: 28px;
  background: url(/staticelement/img/title_ico_01.jpg) right no-repeat;
  display: inline-block;
  color: #838383;
}

#tab_content h3
{
  margin-bottom:0.4em;
  font-size: 1.3rem;
  font-size: 20.8px;
}

#tab_content p
{
  line-height: 1.65;
  font-size: 1.125rem;
  font-size: 18px;
  margin-bottom: 1em;
  color: #333;
}

#tab_content p > a
{
  color: blue;
  text-decoration: underline;
}

.list_ta
{
  margin: 20px 5px 5px 15px;
  display: none;
}

#menu_left_fix .tabs li .list_ta a
{
  font-size: 0.95em;
  color: #929292;
  display: block;
  width: 132px;
  margin-bottom: 10px;
}

.list_ta a:hover
{
  text-decoration: underline;
}

@media (min-width: 900px)
{
  #menu_left_fix
  {
    position: fixed;
    top: 85px;
    float: left;
    width: 300px;

    padding: 25px 0 0 20px;
  }

  #menu_left_fix h2
  {
    background: inherit;

    text-align: left;
    cursor: auto;
    width: auto;
    padding-bottom: 5px;
    padding-left: 10px;
    border-bottom: 2px solid #ddd;
  }

  #menu_left_fix .tabs
  {
    margin-left: 15px;
    margin-top: 15px;
  }

  #menu_left_fix .tabs li
  {
    padding: 0;
    padding-bottom: 18px;
    text-align: left;

    background: inherit;

    display: block;
  }

  #menu_left_fix .tabs li:hover
  {
    background: inherit;
  }

  #tab_content
  {
    padding: 30px 40px;
    padding-left: 320px;
  }
}

table.guidetable
{
  margin: 20px auto;
  font-size: 1rem;
  font-size: 16px;
  text-align: left;
  width: 100%;
  border: 1px solid #c8c8c8;
}

table.guidetable th
{
  background: #7d7d7d;
  color: #fff;
  line-height: 15pt;
  padding: 12px 12px;
}

table.guidetable tr.title
{
  background: #e5e5e5;
  color: #000;
  line-height: 15pt;
  padding: 5px 5px;
  border-top: 1px solid #7d7d7d;
}

table.guidetable tr.white
{
  background: #fff;
  color: #000;
  line-height: 15pt;
  padding: 5px 5px;
}

table.guidetable tr.gray
{
  background: #f7f7f7;
  line-height: 15pt;
  padding: 5px 5px;
}

table.guidetable td
{
  color: #333;
  padding: 10px;
  border-left: 1px dotted #f1f1f1;
}

/* tooltip styling. by default the element to be styled is .tooltip */

.tooltip
{
  display: none;
  background: url(/staticelement/img/main/opacity_bg.png);
  border: 1px solid #fff;
  padding: 3px;
  font-size: 13px;
  -moz-box-shadow: 2px 2px 11px #666;
  -webkit-box-shadow: 2px 2px 11px #666;
  height: auto;
  padding: 10px;
  color: #fff;
  z-index: 99999;
}

/* footer_2 */

#footer_2
{
  height: 190px;
  padding-top: 37px;
}

#footer_menu
{
  position: relative;
  color: #174c9c;
  width: 852px;
  margin: 0 auto;
  font-size: 16px;
  font-size: 1rem;
  font-weight: bold;
  line-height: 35px;
  vertical-align: text-top;
}

#footer_menu span
{
  background-color: #e4e4e4;
  padding: 3px 0;
}

#footer_menu a
{
  color: #174c9c;
  padding: 0 15px;
}

#footer_menu h4
{
  left: 0 !important;
  margin-top: 4px;
  display: inline-block;
  cursor: default;
  font-weight: bold;
  letter-spacing: 1px;
  padding-right: 10px;
  margin-right: 10px;
}

#footer_menu h4:after
{
  content: "";
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
  width: 7px;
  height: 7px;
  overflow: hidden;
  background: url(/staticelement/img/main/ico.png) no-repeat 0 -819px;
}

#footer_menu ul
{
  position: absolute;
  top: -150px;
  top: -moz-calc(-150px);
  z-index: 999;
  /*
  top: 1em;
  top: -moz-calc(1em);
  */
  left: 580px;
  display: none;
  padding: 0 !important;
  background: white;
  border: 1px solid #f4f4f4;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
}

#footer_menu ul li
{
  position: static !important;
  float: none !important;
  display: inline !important;
  margin-right: 0 !important;
}

#footer_menu ul li a
{
  display: block;
  padding: 5px 52px 5px 12px;
  height: 1%;
  color: #a7a7a7 !important;
  font-weight: normal;
  white-space: nowrap;
  letter-spacing: 0 !important;
  cursor: pointer;
}

#footer_menu ul li a:hover
{
  background-color: #f6f6f6;
}

/* footer_3 */

#footer_3
{
  margin-top: 20px;
  background-color: #f8f8f8;
  height: 190px;
  padding-top: 37px;
}

#footer_3 #footer_menu
{
  width: auto;
}

#footer_3 #theCopyright2
{
  color: #969696;
}

.rwd_body
{
  padding-top: 84px;
}

.rwd_body_prog
{
  padding-top: 51px;
}

@media (min-width: 830px)
{
  .rwd_body
  {
    padding-top: 110px;
  }

  .rwd_body_prog
  {
    padding-top: 77px;
  }
}

/* buckets */

body .buckets
{
  -moz-transition: -moz-transform .4s;
  -o-transition: -o-transform .4s;
  -webkit-transition: -webkit-transform .4s;
  transition: transform .4s;
}

@media (max-width: 1000px) and (min-width: 80px)
{
  body.nav-open .buckets
  {
    -moz-transform: translate3d(-100%,0,0);
    -ms-transform: translate3d(-100%,0,0);
    -webkit-transform: translate3d(-100%,0,0);
    transform: translate3d(-100%,0,0);
  }
}

.drawer.buckets
{
  background: #013a91;
  display: block;
  font-weight: 500;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding: 84px 0 0;
  width: 100%;
  font-size: 20px;
  font-size: 1.25rem;
  position: fixed;
  top: 0;
  left: 100%;
  z-index: 99;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.rwd_body_prog .drawer.buckets
{
  background: #f0f0f0;
  padding: 51px 0 0;
  border-left: 1px solid #f2f2f2;
}

@media (min-width: 480px)
{
  .drawer.buckets
  {
    width: 320px;
  }
}

@media (min-width: 830px)
{
  .drawer.buckets
  {
    padding: 110px 0 0;
  }
}

.drawer>ul
{
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

.drawer>ul li
{
  list-style: none;
  vertical-align: top;
}

.drawer.sections>ul
{
  background: #1956b3;
  white-space: nowrap;
}

.rwd_body_prog .drawer.sections>ul
{
  background: #fefefe;
}

.drawer.sections>ul a, .drawer.sections>ul a:active
{
  color: white;
}

.rwd_body_prog .drawer.sections>ul a, .rwd_body_prog .drawer.sections>ul a:active
{
  color: black;
}

.drawer.sections>ul .section.service a,
.drawer.sections>ul .section.service a:active
{
  color: #ffe24e;
}

.rwd_body_prog .drawer.sections>ul .section.service a,
.rwd_body_prog .drawer.sections>ul .section.service a:active
{
  color: #fb5629;
}

.drawer.sections
{
  box-shadow: none;
  display: block;
  font-weight: 500;
  height: auto;
  position: relative;
  top: 0;
  font-size: 16px;
  font-size: 1rem;
}

.drawer.buckets .bucket>a,
.drawer.buckets .bucket>a:active
{
  color: white;
  padding: 10px 10px 10px 10px;
  -moz-transition: color .2s;
  -o-transition: color .2s;
  -webkit-transition: color .2s;
  transition: color .2s;
}

.rwd_body_prog .drawer.buckets .bucket>a,
.rwd_body_prog .drawer.buckets .bucket>a:active
{
  color: black;
}

.drawer.buckets .bucket>a:hover
{
  background: #1b63d9;
}

.rwd_body_prog .drawer.buckets .bucket>a:hover
{
  background: #e0e0e0;
}

.drawer>ul li>a
{
  -moz-transform: translate3d(0,0,0);
  -ms-transform: translate3d(0,0,0);
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

.drawer.sections a
{
  display: block;
}

.buckets a, .sections a
{
  line-height: 35px;
  padding: 0 15px;
}

@media (max-width: 1000px) and (min-width: 80px)
{
  .bucket>a
  {
    border-top: 1px solid #1954b2;
    border-bottom: 1px solid #1954b2;
    display: block;
    font-size: 20px;
    font-size: 1.25rem;
  }

  .rwd_body_prog .bucket>a
  {
    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
  }
}

@media (max-width: 1000px) and (min-width: 80px)
{
  .drawer.sections>ul .section:hover
  {
    background: #1b63d9;
  }

  .rwd_body_prog .drawer.sections>ul .section:hover
  {
    background: #e0e0e0;
  }
}

/* search button */

.search_button
{
  background: url(/staticelement/img/main/search_ico_04.png) no-repeat center center;
  cursor: pointer;
  display: block;
  float: right;
  width: 50px;
  height: 50px;
}

#rwd_header_prog .search_button
{
  background: url(/staticelement/img/main/search_ico_03.png) no-repeat center center;
}

.search-open .search_button
{
  background-color: #10408a;
}

.search-open #rwd_header_prog .search_button
{
  background-color: #e0e0e0;
}

.search-open #rwd_header .header_darkblue, .search-open #rwd_header_prog .header_darkblue
{
  height: 40px;
  display: block;
}

.header_darkblue input
{
  background-color: transparent;
  border: 0px solid;
  height: 40px;
  width: 100%;
  padding: 0 10px;

  font-size: 16px;
  font-size: 1rem;
  line-height: 40px;

  color: #ccc;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#rwd_header_prog .header_darkblue input
{
  color: #919191;
}

.header_darkblue input:focus
{
  outline: 0;
}

#single_search_box
{
  padding-right: 50px;
}

#single_search_button
{
  width: 50px;
  margin-left: -50px;

  color: rgb(106,154,245);
  font-size: 17px;
  cursor: pointer;
}

#rwd_header_prog .header_darkblue #single_search_button
{
  color: #919191;
}

@media (min-width: 510px)
{
  .search_button
  {
    display: none;
  }

  .search-open #rwd_header .header_darkblue, .search-open #rwd_header_prog .header_darkblue
  {
    display: none;
  }
}

/* three bar */

.threebar_box
{
  cursor: pointer;
  display: block;
  float: right;
  width: 50px;
  /*
  height: 55px;
  padding-top: 5px;
  */
  height: 50px;
}

.threebar_box .threebar
{
  background: #fff;
  height: 3px;
  margin: -1.5px 0 0 -10px;
  width: 20px;
  position: relative;
  top: 50%;
  left: 50%;

  -moz-transition: background .3s;
  -o-transition: background .3s;
  -webkit-transition: background .3s;
  transition: background .3s;
}

.threebar_box.prog .threebar
{
  background: #b0b0b0;
}

.threebar_box .threebar:after, .threebar_box .threebar:before
{
  position: absolute;
  background: #fff;
  content: '';
  height: 3px;
  width: 20px;
  left: 0;
  -moz-transition: -moz-transform .3s,background .3s;
  -o-transition: -o-transform .3s,background .3s;
  -webkit-transition: -webkit-transform .3s,background .3s;
}

.threebar_box.prog .threebar:after, .threebar_box.prog .threebar:before
{
  background: #b0b0b0;
}

.threebar_box .threebar:before
{
  top: -6px;
  transition: transform .3s,background .3s;
  -moz-transform-origin: center center;
  -ms-transform-origin: center center;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}

.threebar_box .threebar:after
{
  bottom: -6px;
  transition: transform .3s,background .3s;
  -moz-transform-origin: center center;
  -ms-transform-origin: center center;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}

.nav-open .threebar_box .threebar
{
  background: 0 0;
}

.nav-open .threebar_box .threebar:before
{
  -moz-transform: translate(0,6px)rotate(-45deg);
  -ms-transform: translate(0,6px)rotate(-45deg);
  -webkit-transform: translate(0,6px)rotate(-45deg);
  transform: translate(0,6px)rotate(-45deg);
}

.nav-open .threebar_box .threebar:after
{
  -moz-transform: translate(0,-6px)rotate(45deg);
  -ms-transform: translate(0,-6px)rotate(45deg);
  -webkit-transform: translate(0,-6px)rotate(45deg);
  transform: translate(0,-6px)rotate(45deg);
}

@media (min-width: 860px)
{
  .threebar_box
  {
    display: none;
  }
}

/* ad */

.NTDAPTVWeb_NewsArticle_TopHugePlatinum,
.NTDAPTVWeb_Channel_TopHugePlatinum,
.NTDAPTVWeb_Tag_TopHugePlatinum,
.NTDAPTVWeb_Live_TopHugePlatinum,
.NTDAPTVWeb_ProgramCategory_TopHugePlatinum,
.NTDAPTVWeb_ProgramCategory_BottomHugeGold,
.NTDAPTVWeb_Program_TopHugePlatinum,
.NTDAPTVWeb_Schedule_TopHugePlatinum,
.NTDAPTVWeb_ProgramIndex_TopHugePlatinum,
.NTDAPTVWeb_TopicIndex_TopHugePlatinum,
.NTDAPTVWeb_Search_TopHugePlatinum
{
  min-height: 90px;
}

@media (max-width:1000px)
{
  .NTDAPTVWeb_NewsArticle_TopHugePlatinum,
  .NTDAPTVWeb_Channel_TopHugePlatinum,
  .NTDAPTVWeb_Tag_TopHugePlatinum,
  .NTDAPTVWeb_Live_TopHugePlatinum,
  .NTDAPTVWeb_ProgramCategory_TopHugePlatinum,
  .NTDAPTVWeb_ProgramCategory_BottomHugeGold,
  .NTDAPTVWeb_Program_TopHugePlatinum,
  .NTDAPTVWeb_Schedule_TopHugePlatinum,
  .NTDAPTVWeb_ProgramIndex_TopHugePlatinum,
  .NTDAPTVWeb_TopicIndex_TopHugePlatinum,
  .NTDAPTVWeb_Search_TopHugePlatinum
  {
    display: none;
  }
}

@media (min-width:1000px)
{
  .NTDAPTVWeb_Program_TopPlatinum
  {
    display: none;
  }
}

.NTDAPTVWeb_Schedule_MiddleGold_M2,
.NTDAPTVWeb_Schedule_MiddleGold_M3
{
  display: none;
  margin-bottom: 25px;
}

@media (min-width: 550px)
{
  .NTDAPTVWeb_Schedule_MiddleGold_M2,
  .NTDAPTVWeb_Schedule_MiddleGold_M3
  {
    display: block;
  }
}

@media (min-width: 1015px)
{
  .NTDAPTVWeb_Schedule_MiddleGold_M2,
  .NTDAPTVWeb_Schedule_MiddleGold_M3
  {
    top: -10px;
    left: -58px;
    position: relative;
    margin-bottom: 0px;
  }
}

/* social buttons */

.social_buttons
{
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  /* fixed layout in small screen */
  height: 30px;

  padding: 5px 0;
  font-size: 0;
}

/* line */

.ios .line-button,
.android .line-button,
.iemobile .line-button
{
  display: inline-block;
}

.line-button
{
  display: none;
  margin-right: 10px;
  position: relative;
}

.line-button img
{
  vertical-align: bottom;
}

/* facebook */

.fb-share-button
{
  display: inline-block;
}

.fb-like
{
  margin-right: 5px;
  display: inline-block;
}

/*
.fb-like > span > iframe
{
  height: 20px;
}
*/

.fb_comments_box .fb_iframe_widget,
.fb_comments_box .fb_iframe_widget span,
.fb_comments_box .fb_iframe_widget span iframe[style]
{
  min-width: 100% !important;
  width: 100% !important;
}

/* twitter */

.twitter-box
{
  position: relative;
  margin-right: 10px;
  display: inline-block;
}

.twitter-box > iframe
{
  display: inline-block;
  vertical-align: bottom;
}

/* google plus */

/*
.g-plusone-box
{
  display: inline-block;
  line-height: 0px;
}

.g-plusone-box > div
{
  vertical-align: bottom !important;
}
*/

/* margin */

.g_margin_top
{
  margin-top: 10px;
}

.g_margin_bottom
{
  margin-bottom: 10px;
}

/* live stream */

#rwd_player_box
{
  color: #fff;
  background-color: #000;
}

#rwd_player_outer
{
  max-width: 640px;
  margin: 0 auto;
}

#rwd_player_wrapper
{
  /* 4:3 */
  /* padding-bottom: 75% !important; */
  /* 16:9 */
  padding-bottom: 56.25% !important;
  padding-top: 20px !important;
  width: auto !important;
  height: 0 !important;
}

#rwd_player_wrapper embed,
#rwd_player_wrapper object
{
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  left: 0 !important;
  top: 0 !important;
}

/* live page tab content */

#view_right_block
{
  padding-top: 6px;
  background-color: #000;
}

/* root element for tabs  */

ul.live_tabs
{
  margin: 0 !important;
  padding: 0;
  border-bottom: 1px solid #666;

  height: 30px;
}

/* single tab */

ul.live_tabs li
{
  float: left;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

ul.live_tabs #t1
{
  margin-left: 10px;
}

/* link inside the tab. uses a background image */

ul.live_tabs a
{
  float: left;
  font-size: 14px;
  display: block;
  padding: 5px 20px;
  text-decoration: none;
  border: 1px solid #666;
  border-bottom: 0px;
  height: 18px;
  background-color: #1a1a1a;
  color: #fff;
  margin-right: 2px;
  position: relative;
  top: 1px;
  outline: 0;
  -moz-border-radius: 4px 4px 0 0;
}

ul.live_tabs a:hover
{
  background-color: #1a1a1a;
  color: #eee;
}

/* selected tab */

ul.live_tabs a.current
{
  background-color: #1a1a1a;
  border-bottom: 1px solid #1a1a1a;
  color: #fff;
  cursor: default;
}

/* tab pane */

.live_panes div
{
  font-size: 14px;
  display: none;
  border-top: 1px solid #666;
  border-bottom: 1px solid #666;
  border-width: 0 1px 1px 1px;
  padding: 0px 20px;
  color: #fff;
  background-color: #1a1a1a;
  overflow: auto;

  height: 220px;

  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.live_panes #tab1
{
  padding: 0;
  padding-top: 5px;
}

.live_panes #tab2 h3
{
  font-size: 20px;
  margin: 25px 0;
}

.live_panes #tab2 p
{
  line-height: 1.7em;
}

#ippotv_btn
{
  font-size: 1.5em;
  font-weight: bold;
  color: white;
  padding: 10px 0 0 100px;
  margin: 20px auto 25px auto;
  display: block;
  width: 116px;
  height: 36px;
  background: url(../../staticelement/img/IPPOTV_ico.jpg) no-repeat;
}

#ippotv_btn:hover
{
  text-decoration: underline;
}

.ad_box_01
{
  background: url(/staticelement/img/default_ad_01_bg.png)  no-repeat;
  text-align: center;

  display: none;
}

.ad_box_01 > div
{
  margin: 0 auto;
}

@media (min-width: 830px)
{
  ul.live_tabs
  {
    position: absolute;
  }

  .live_panes div
  {
    position: absolute;
    top: 37px;
    bottom: 60px;
    width: 260px;
    right: 0px;

    height: auto;
  }

  #ippotv_btn
  {
    margin-bottom: 0;
  }

  .ad_box_01
  {
    display: block;

    height: 60px;
    width: 260px;

    position: absolute;
    bottom: 0;
  }
}

.live_latest_prog_box
{
  height: 240px;
  position: relative;
}

.live_latest_prog_box .scrollable
{
  margin-left: 33px;
}

.live_latest_prog_box a.browse
{
  position: absolute;
  top: 50%;
  margin-top: -16px;
}

.live_latest_prog_box a.left
{
  margin-left: 0px;
}

.live_latest_prog_box a.right
{
  right: 0px;
}

.live_latest_prog_box .scrollable .s_item
{
  padding: 20px 10px 10px 10px;
}

.live_lpbox_ad_box
{
  background: url('/staticelement/widget/scrollabl/default_ad_02_bg.jpg') no-repeat;
  position: absolute;
  top: 35px;
  right: 35px;
  width: 200px;
  height: 200px;
  overflow: hidden;

  display: none;
}

#page_bar
{
  margin-top: 15px;
  margin-bottom: 15px;
  /*
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
  */
  text-align: center;
}

.fb_like_btn_live
{
  margin: 10px 0;
  height: 20px;
}

.fb_like_btn_live > span
{
  vertical-align: top;
  margin: 5px;
  line-height: 20px;
}

.live_sp_box
{
  font-size: 0px;
}

@media (min-width: 450px)
{
  .live_lpbox_ad_box
  {
    display: block;
  }
}

@media (min-width: 550px)
{
  #page_bar
  {
    text-align: left;
  }

  .live_sp_box
  {
    float: left;
  }

  .fb_like_btn_live
  {
    float: right;
  }
}

.live_sp_box a
{
  margin-right: 10px;
}

.live_sp_box .line
{
  display: none;
}

.ios .live_sp_box .line,
.android .live_sp_box .line,
.iemobile .live_sp_box .line
{
  display: inline;
}

/* article page */

.spx_box
{
  margin: 10px 0;
}

.spx_box .font_size
{
  display: none;
}

.spx_box_bottom
{
  display: block;
  margin-bottom: 15px;
}

.sp_box
{
  font-size: 0px;
  margin: 10px 0;
}

.sp_box.bottom
{
  margin: 15px 0;
}

@media (min-width: 450px)
{
  .spx_box .font_size
  {
    display: block;
  }

  .spx_box_bottom
  {
    display: none;
  }
}

.sp_box a
{
  margin-right: 10px;
}

.sp_box .line
{
  display: none;
}

.ios .sp_box .line,
.android .sp_box .line,
.iemobile .sp_box .line
{
  display: inline;
}

/* error 404 page */

.error404_wrapper
{
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  position: relative;

  padding-top: 20px;
  margin: 0 auto;
}

.error404_wrapper .error404_leftimage
{
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  display: none;
}

.error404_wrapper .error404_image
{
  display: block;

  padding-top: 20px;

  max-width: 150px;
  margin: 0 auto;
}

.error404_wrapper .error404_leftimage img, .error404_wrapper .error404_image img
{
  width: 100%;
}

.error404_wrapper .error404_info
{
  max-width: 450px;
  margin: 0 auto;
}

.error404_info h1
{
  font-weight: bold;

  font-size: 50px;
  font-size: 3.125rem;

  text-align: center;
}

.error404_info .error404_desc
{
  font-size: 18px;
  font-size: 1.125rem;

  line-height: 20px;

  padding: 20px 0;
}

.error404_info .error404_buttons
{
  text-align: center;
}

.error404_info .error404_buttons a, .error404_info .error404_buttons a:active
{
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  font-size: 18px;
  font-size: 1.125rem;

  line-height: 20px;
  background-color: #164b9b;
  color: white;

  text-align: center;
  display: inline-block;

  width: 30%;
  padding: 20px 0;
  margin-right: 5px;
}

.error404_info .error404_buttons a:last-child
{
  margin-right: 0px;
}

@media (min-width: 680px)
{
  .error404_wrapper
  {
    padding-top: 80px;

    max-width: 750px;
    padding-right: 450px;
  }

  .error404_wrapper .error404_leftimage
  {
    float: left;
    width: 100%;
    display: block;

    padding: 20px;
  }

  .error404_wrapper .error404_image
  {
    display: none;
  }

  .error404_wrapper .error404_info
  {
    float: right;
    width: 450px;
    max-width: inherit;
    margin-right: -450px;
  }

  .error404_info h1
  {
    text-align: left;
  }

  .error404_info .error404_desc
  {
    padding: 40px 0;
  }

  .error404_info .error404_buttons
  {
    text-align: inherit;
  }

  .error404_info .error404_buttons a, .error404_info .error404_buttons a:active
  {
    width: 25%;
    padding: 30px 0;
    margin-right: 20px;
  }
}

/* merged from home.css */

/*--------Headlines------------*/

#Headlines
{
  position: relative;
  z-index: 1;
  top: 0px;
  left: 0px;
}

/*
#Headlines h3
{
  color: #0754c8;
}
*/

#home_scrollable_box
{
  position: relative;
  margin: 0 auto;
  border: 1px solid #e6e6e6;
}

/* v2014_Important */

#v2014_Important
{
  border-top: 1px solid #ddd;
  /*
  padding: 12px 0 0 0;
  margin-top: 30px;
  width: 660px;
  height: 475px;
  height: 510px;
  */
  overflow: hidden;
}

#v2014_Important .in_news a
{
  float: left;
  color: #b01415;
  font-size: 1.17em;
  font-weight: bold;
  width: 80px;
  margin-right: 35px;
}

#v2014_Important .tabs li
{
  margin: 5px 0;
  /*
  margin-right: 20px;
  */
  border-right: 0px;
  display: inline-block;
}

#v2014_Important .tabs li a
{
  font-weight: bold;
  cursor: pointer;
  padding: 5px 8px;
}

#v2014_Important .tabs li a.current
{
  background-color: #164b9b;
  color: #fff;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
}

#v2014_Important .tabs li a:hover
{
  background-color: #164b9b;
  color: #fff;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
}

#v2014_Important .tabs li.special a
{
  color: #164b9b;
}

#v2014_Important .tabs li.special a.current
{
  color: #fff;
}

#v2014_Important .tabs li.special a:hover
{
  color: #fff;
}

#v2014_Important .tabs
{
  margin: 10px 0;
}

#v2014_Important .panes
{
  border-top: 1px dotted #ddd;
}

#topic_home
{
  height: 268px;
  overflow: hidden;

  /*
  border-bottom: 1px dotted #ddd;
  */
}

#topic_home h3
{
  color: #cc181b;
  text-align: right;
  position: relative;
}

#topic_home h3 .pointer
{
  position: absolute;
  left: 260px;
  width: 25px;
  height: 12px;
  background: url(/staticelement/img/main/current.png) no-repeat 0 0;
  overflow: hidden;
  z-index: 100;
}

#topic_home .topic_itembox h3
{
  color: #000;
  text-align: left;
  position: inherit;
  margin: 10px;
}

.topic_list
{
  float: left;
}

.topic_list a {
  margin: 0 14px;
  font-size: 1.125rem;
  font-size: 18px;
  line-height: 1.75;
  color: #939393;
}

.topic_list a:hover
{
  color: #0753ca;
}

/* news_tabs */

#news_tabs
{
  margin: 10px;
  padding-bottom: 7px;
  /*
  border-bottom: 1px dotted #ddd;
  */
}

#news_tabs .tab_item
{
  font-size: 0.95em;
  color: #164b9b;
  /*
  color: #9b9b9b;
  */
  font-weight: bold;
}

#news_tabs .tab_item:hover, #news_tabs .focus
{
  color: #164b9b;
  cursor: pointer;
}

.rwd_news_tab
{
  width: 32%;
  text-align: center;
  float: left;
}

.rwd_news_tab.mid
{
  border: 1px solid #eee;
  border-top: 0;
  border-bottom: 0;
}

/* scroll_style2 */

.scroll_style2 .scrollable_box
{
  height: 210px;
}

.scroll_style2 .scrollable
{
  width: 884px;
  height: 180px;
  margin-top: 15px;
  margin-left: 0;
}

.scroll_style2 .scrollable .s_item
{
  padding: 0 8px 10px 8px;
}

.scroll_style2 .items div
{
  width: 900px;
}

.scroll_style2 .scroll_navi
{
  float: left;
}

.scroll_style2 .guide
{
  float: right;
  position: relative;
  z-index: 100;
  margin-top: -32px;
}

.scroll_style2 .guide a
{
  font-size: 0.9em;
  color: #cc181b;
}

.scroll_style2 .scrollable .s_item:nth-child(5)
{
  border-right: 0;
}

.scroll_style2 .scrollable h4
{
  font-size: 0.9em;
  color: #a6a6a6;
  font-weight: normal;
}

.scroll_style2 a.browse
{
  background: url(/staticelement/widget/scrollabl/hori_large_02.jpg) no-repeat;
  display: block;
  margin-top: 0;
  width: 47px;
  height: 200px;
  float: left;
  cursor: pointer;
  font-size: 1px;
}

/* right */

.scroll_style2 a.right
{
  /*
  position: absolute;
  left: 933px;
  */
  position: relative;
  left: auto;
  background-position: 0 -200px;
  clear: right;
  margin-left: 0;
}

.scroll_style2 a.right:hover
{
  background-position: -47px -200px;
}

.scroll_style2 a.right:active
{
  background-position: -47px -200px;
}

/* left */

.scroll_style2 a.left
{
  margin-left: 0;
}

.scroll_style2 a.left:hover
{
  background-position: -47px 0;
}

.scroll_style2 a.left:active
{
  background-position: -47px 0;
}

/* merged from news.css */

#NewsHeadlines
{
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  margin-top: 10px;
  float: left;

  width: 100%;

  overflow: hidden;
}

[data-eq-state="2-column-grid"] #NewsHeadlines
{
  width: 50%;
  padding-right: 10px;
}

#NewsHeadlines h3
{
  color: #0754c8;
  margin-bottom: 5px;
}

.news_topic_box
{
  border-top: 1px solid #ddd;
  height: 160px;
  width: 660px;
  overflow: hidden;
}

.news_topic_box p
{
  margin: 15px 10px 10px 0;
  font-size: 0.9em;
  display: block;
  overflow: hidden;
  height: 70px;
}

.top_news
{
  width: 660px;
  height: 160px;
}

.top_news_l
{
  float: left;
  width: 320px;
  height: 160px;
  margin-right: 18px;
  background-color: #f4f4f4;
  position: relative;
}

.top_news_r
{
  padding: 15px 10px 0 0;
}

.news_topic_box .top_news_r p
{
  margin: 0 0 10px 0;
}

.top_news .topic_ico
{
  position: absolute;
  top: 0;
  left: 0;
}

.top_news .topic_name
{
  position: absolute;
  width: 300px;
  top: 94px;
  left: 0;
  font-weight: bold;
  padding: 2px 10px;
  color: #fff;
  background: url(/staticelement/img/main/gradient_bg.png) repeat-x;
}

.top_news h3
{
  margin: 3px 0 0 10px;
}

.top_news h3 a
{
  font-size: 0.9em;
  color: #174c9c;
}

.top_news .top_news_desc
{
  color: #828282;
}

img.top_main_img
{
  width: 320px;
}

/* v2014_importantNews */

#v2014_importantNews
{
  height: 432px;
  overflow: hidden;
}

/* merged from live.css */

#view_block
{
  padding-top: 15px;
  padding-bottom: 10px;
}

#tab_box_01
{
  height: 24px;
  margin-top: 15px;
}

#tab_box_01 a
{
  float: right;
  display: block;
  height: 19px;
  width: 105px;
  /*
  margin-left: 193px;
  */
  border: 1px solid #c2c2c2;
  padding-top: 3px;
  text-align: center;
  background-color: #e0e0e0;
  font-size: 14px;
}

#tab_box_01 a:hover
{
  color: #b93232;
}

#live_scrollable_box
{
  position: relative;
  margin: 0 auto;
  height: 200px;
  border: 1px solid #e6e6e6;
  background-color: #fff;
}

/* schedule */

ul#schedule
{
  list-style: none;
}

#schedule li
{
  padding: 5px 10px 5px 38px;
  *padding: 13px 10px 5px 38px;
  vertical-align: middle;
}

#schedule li .time
{
  display: table-cell;
  *display: block;
  *float: left;
  height: 32px;
  vertical-align: middle;
  width: 50px;
}

#schedule li .name
{
  display: table-cell;
  *padding-top: 8px;
  height: 32px;
  vertical-align: middle;
}

#schedule li.curr
{
  background: url(../../staticelement/img/arror_ico.jpg) no-repeat #3a3a3a;
}

#schedule li:hover
{
  background-color: #3a3a3a;
}

#live_bg
{
  background-color: #595959;
  padding-bottom: 35px;
}

/* merged from article.css */

.rwd_body_prog #top_prog_box div.category_name a,
.rwd_body_prog #top_prog_box div.category_name a:active
{
  color: #fff;
}

.rwd_body_prog #top_prog_box div.category_name
{
  font-size: 2rem;
  font-size: 32px;
  color: #fff;
  padding: 10px 0 10px 0;
  text-shadow: 2px 2px #333;
}

.rwd_body_prog #top_prog_box h1.article_title
{
  font-size: 1.7rem;
  font-size: 27.2px;
  text-align: center;
  color: #fff;
  text-shadow: 2px 2px #333;
  margin: 20px 0 20px 0;
  padding: 0;
  line-height: 1.5;
}

.rwd_body_prog #top_prog_box .top_prog_dummmy
{
  padding: 0 0 42px 0;
}

#top_prog_box
{
  background-position: 0% 0%;
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF313131', endColorstr='#FF000000',GradientType=0)";
  background-image: -ms-linear-gradient(top, #313131 0%, #000 100%);
  background-color: #494949;
  background-repeat: repeat;
  background-attachment: scroll;
}

#top_prog_box .vjs-default-skin .vjs-control-bar
{
  background-color: #5e5e5e;
}

#article_box.prog
{
  max-width: 810px;
  margin: 10px auto;
  padding: 0px 10px;
}

@media (min-width: 852px)
{
  #article_box.prog
  {
    padding: 0px;
  }
}

h1.article_title
{
  margin: 5px 0;
  padding: 5px 0;
  font-weight: bold;
  font-size: 28px;
  font-size: 1.75rem;
  line-height: 1.21429;
}

@media (min-width: 480px)
{
  h1.article_title
  {
    margin: 10px 0;
    padding: 0;
  }
}

@media (min-width: 640px)
{
  h1.article_title
  {
    font-size: 52px;
    font-size: 3.25rem;
    line-height: 1.07692;
    margin-top: 15px;
  }
}

@media (min-width: 800px)
{
  h1.article_title
  {
    margin-top: 25px;
  }
}

@media (min-width: 960px)
{
  h1.article_title
  {
    font-size: 62px;
    font-size: 3.875rem;
    line-height: 1.06452;
  }
}

.player_box_outter
{
  background-color: #000;
}

.player_box_wrapper
{
  max-width: 720px;
  background-color: #000;
  margin: 0 auto;
}

.player_box_wrapper .video-js
{
  padding-bottom: 66.67%;
  width: auto;
  height: 0;
}

.player_box_wrapper .vjs-fullscreen
{
  padding-bottom: 0px;
}

.rwd_body_prog .player_box_wrapper
{
  max-width: 982px;
  background-color: #000;
  margin: 0 auto;
}

.rwd_body_prog .player_box_wrapper .video-js
{
  padding-bottom: 56.25%;
  width: auto;
  height: 0;
}

.rwd_body_prog .player_box_wrapper .vjs-fullscreen
{
  padding-bottom: 0px;
}

.share_box
{
  padding: 7px 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;
}

.embed_btn
{
  position: relative;
  z-index: 1;
  cursor: pointer;
  float: left;
  display: block;
  width: 32px;
  height: 32px;
  background: url(/staticelement/img/main/embed_ico.jpg) no-repeat;
}

.font_size
{
  line-height: 32px;
  float: right;
  /*
  width: 100px;
  margin-left: 15px;
  */
}

.font_size a, .font_size span
{
  margin: 0 0 0 10px;
  color: #666;
  height: 30px;
  width: 30px;
  float: left;
  background: url(/staticelement/img/main/font-sprites.gif) no-repeat;
  font-size: 0px;
}

.font_size a
{
  cursor: pointer;
}

.font_size .word
{
  background-position: 0 0;
}

.font_size .small
{
  background-position: -90px -30px;
}

.font_size .small:hover, .font_size .small.curr
{
  background-position: -90px 0;
}

.font_size .medium
{
  background-position: -60px -30px;
}

.font_size .medium:hover, .font_size .medium.curr
{
  background-position: -60px 0;
}

.font_size .large
{
  background-position: -30px -30px;
}

.font_size .large:hover, .font_size .large.curr
{
  background-position: -30px 0;
}

/* article_content */

div#article_content.channel_page
{
  margin-top: 15px;
}

#article_content a, #article_content a:active
{
  color: #0000ee;
  text-decoration: underline;

  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* this is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* instead use this non-standard one: */
  word-break: break-word;
}

#article_content p, #article_content div
{
  margin-bottom: 15px;

  /*
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5625;
  */

  font-size: 18px;
  font-size: 1.125rem;
  /*
  line-height: 1.50694;
  */
  line-height: 1.72em;
}

#article_content.font_large, #article_content.font_large p, #article_content.font_large a, #article_content.font_large div
{
  /*
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.5625;
  */
  margin-bottom: 21px;

  font-size: 22px;
  font-size: 1.375rem;
  /*
  line-height: 1.50625;
  */
  line-height: 1.72em;
}

#article_content.font_small, #article_content.font_small p, #article_content.font_small a, #article_content.font_small div
{
  /*
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5625;
  */
  margin-bottom: 13px;

  font-size: 16px;
  font-size: 1rem;
  /*
  line-height: 1.5625;
  */
  line-height: 1.78em;
}

@media (min-width: 640px)and (max-width: 959px)
{
  /*
  #article_content p
  {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5625;
  }

  #article_content.font_large, #article_content.font_large p, #article_content.font_large a
  {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.50694;
  }
  */
}

@media (min-width: 960px)
{
  /*
  #article_content p
  {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.50694;
  }

  #article_content.font_large, #article_content.font_large p, #article_content.font_large a
  {
    font-size: 22px;
    font-size: 1.25rem;
    line-height: 1.50625;
  }

  #article_content.font_small, #article_content.font_small p, #article_content.font_small a
  {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5625;
  }
  */
}

/* article h1, h2, h3 styles */

#article_content h1, #article_content h2, #article_content h3
{
  margin: 15px 0 16px 0;
}

#article_content h3
{
  padding-top: 8px;

  font-size: 20px;
  font-size: 1.25rem;

  line-height: 1.3;
}

#article_content h2
{
  padding-top: 8px;

  font-size: 24px;
  font-size: 1.5rem;

  line-height: 1.25;
}

#article_content h1
{
  padding-top: 8px;

  font-size: 28px;
  font-size: 1.75rem;

  line-height: 1.5;
}

#article_content.font_large h3
{
  padding-top: 10px;

  font-size: 24px;
  font-size: 1.5rem;

  line-height: 1.25;
}

#article_content.font_large h2
{
  padding-top: 10px;

  font-size: 28px;
  font-size: 1.75rem;

  line-height: 1.5;
}

#article_content.font_large h1
{
  padding-top: 10px;

  font-size: 32px;
  font-size: 2rem;

  line-height: 1.75;
}

#article_content.font_small h3
{
  padding-top: 5px;

  font-size: 18px;
  font-size: 1.125rem;

  line-height: 1.33333;
}

#article_content.font_small h2
{
  padding-top: 5px;

  font-size: 20px;
  font-size: 1.25rem;

  line-height: 1.3;
}

#article_content.font_small h1
{
  padding-top: 5px;

  font-size: 24px;
  font-size: 1.5rem;

  line-height: 1.25;
}

/*
#article_content h3
{
  padding-top: 5px;

  font-size: 18px;
  font-size: 1.125rem;

  line-height: 1.33333;
}

#article_content h2
{
  padding-top: 5px;

  font-size: 20px;
  font-size: 1.25rem;

  line-height: 1.3;
}

#article_content h1
{
  padding-top: 5px;

  font-size: 24px;
  font-size: 1.5rem;

  line-height: 1.25;
}

@media (min-width: 640px)and (max-width: 959px)
{
  #article_content h3
  {
    padding-top: 8px;

    font-size: 20px;
    font-size: 1.25rem;

    line-height: 1.3;
  }

  #article_content h2
  {
    padding-top: 8px;

    font-size: 24px;
    font-size: 1.5rem;

    line-height: 1.25;
  }

  #article_content h1
  {
    padding-top: 8px;

    font-size: 28px;
    font-size: 1.75rem;

    line-height: 1.5;
  }
}

@media (min-width: 960px)
{
  #article_content h3
  {
    padding-top: 10px;

    font-size: 24px;
    font-size: 1.5rem;

    line-height: 1.25
  }

  #article_content h2
  {
    padding-top: 10px;

    font-size: 28px;
    font-size: 1.75rem;

    line-height: 1.5;
  }

  #article_content h1
  {
    padding-top: 10px;

    font-size: 32px;
    font-size: 2rem;

    line-height: 1.75;
  }
}
*/

.article_info
{
  font-size: 12.799999px;
  font-size: 0.8rem;
  color: #737373;
  margin-bottom: 10px;
  line-height: 1.5;
}

@media (min-width: 800px)
{
  .article_info
  {
    font-size: 16px;
    font-size: 1rem;
  }
}

/* article img styles */

#article_content figure
{
  margin: 0;
  /*
  padding: 0 15px 15px 15px;
  */
  width: 100%;
  line-height: 0;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#article_content .ni_figure_center > figure
{
  margin: 0 auto;
}

#article_content .ni_figure_left
{
  float: left;
}

#article_content .ni_figure_right
{
  float: right;
}

#article_content figcaption,
#article_content img
{
  width: 100%;
}

#article_content figcaption
{
  background-color: rgba(0,0,0,0.05);

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  padding: 10px;

  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5625;
}

#article_content.font_large figcaption
{
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.50694;
}

.playing
{
  display: none;
  float: right;
  margin-right: 20px;
}

.current .playing
{
  display: inline;
}

#main_figure
{
  text-align: center;
}

#main_figure .main_fig
{
  width: 100%;
  max-width: 660px;
}

#main_figure .caption
{
  text-align: left;
  color: #333;
  font-size: 0.9em;
  margin-bottom: 10px;
}

/* merged from program.css */

/* top_area */

.top_area h1
{
  padding: 10px 0 10px 0;
  color: #ff5a28;
  font-weight: bold;
}

.top_area .programme_wrapper
{
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  position: relative;
}

.top_area .programme_preview
{
  width: 100%;
}

.top_area .programme_image
{
  line-height: 0px;
}

.top_area .programme_image img
{
  width: 100%;
}

.top_area .programme_info
{
  font-size: 1.125rem;
  font-size: 18px;
  text-align: justify;
}

.top_area .programme_info a, .top_area .programme_info a:active
{
  color: #0000ee;
  text-decoration: underline;

  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* this is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* instead use this non-standard one: */
  word-break: break-word;
}

@media (max-width: 680px)
{
  .top_area .programme_area
  {
    width: 100%;
  }

  .top_area .programme_image
  {
    width: 100%;
  }

  .top_area .programme_info
  {
    padding-bottom: 10px;
  }
}

@media (min-width: 680px) and (max-width: 905px)
{
  .top_area .programme_area
  {
    width: 100%;
  }

  .top_area .programme_image
  {
    float: left;
    width: 255px;
    padding-right: 10px;
    padding-bottom: 10px;
  }

  .top_area .programme_image img
  {
    width: 100%;
  }

  .top_area .programme_info
  {
    padding-bottom: 10px;
  }
}

@media (min-width: 905px)
{
  .top_area .programme_wrapper
  {
    padding-right: 620px;
  }

  .top_area .programme_area
  {
    float: left;
    width: 100%;
  }

  .top_area .programme_preview
  {
    float: right;
    width: 610px;
    margin-right: -620px;
  }

  .top_area .programme_image img
  {
    width: 100%;
  }
}

/* message_top_area */

.message_top_area h1
{
  padding: 10px 0 10px 0;
  color: #ff5a28;
  font-weight: bold;
}

.message_top_area a
{
  color: #ff5a28;
}

.message_top_area .programme_wrapper
{
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  position: relative;
}

.message_top_area .programme_preview
{
  width: 100%;
}

.message_top_area .programme_image
{
  line-height: 0px;
}

.message_top_area .programme_image img
{
  width: 100%;
}

.message_top_area .programme_info
{
  font-size: 1.125rem;
  font-size: 18px;
  text-align: justify;
}

.message_top_area .programme_info a, .message_top_area .programme_info a:active
{
  color: #0000ee;
  text-decoration: underline;

  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* this is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* instead use this non-standard one: */
  word-break: break-word;
}

@media (max-width: 680px)
{
  .message_top_area .programme_area
  {
    width: 100%;
  }

  .message_top_area .programme_image
  {
    width: 100%;
  }

  .message_top_area .programme_info
  {
    padding-bottom: 10px;
  }
}

@media (min-width: 680px) and (max-width: 905px)
{
  .message_top_area .programme_area
  {
    width: 100%;
    margin-bottom: 20px;
  }

  .message_top_area .programme_image
  {
    float: left;
    width: 345px;
    padding-right: 10px;
    padding-bottom: 15px;
  }

  .message_top_area .programme_image img
  {
    width: 100%;
  }

  .message_top_area .programme_info
  {
    padding-bottom: 10px;
  }
}

@media (min-width: 905px)
{
  .message_top_area .programme_area
  {
    width: 100%;
    margin-bottom: 20px;
  }

  .message_top_area .programme_image
  {
    float: left;
    width: 380px;
    padding-right: 10px;
    padding-bottom: 10px;
  }

  .message_top_area .programme_image img
  {
    width: 100%;
  }

  .message_top_area .programme_info
  {
    padding-bottom: 10px;
  }
}

/* player */

.prog_player_box_outter
{
  background-color: #000;
}

.prog_player_box_wrapper
{
  background-color: #000;
  margin: 0 auto;
}

.prog_player_box_wrapper .video-js
{
  padding-bottom: 66.67%;
  width: auto;
  height: 0;
}

.prog_player_box_wrapper .vjs-fullscreen
{
  padding-bottom: 0px;
}

/* prog_page */

#prog_page
{
  background: url(/staticelement/img/main/tab_bg.jpg) top repeat-x;
  z-index: 9;
  border: 1px solid #e3e3e3;
  border-bottom: 2px solid #bab8b9;
  background-color: #fff;
}

#prog_page .tabs, #prog_page .sub_items
{
  float: left;
}

#prog_page .tabs li,#prog_page .sub_items li
{
  height: 42px;
  /*
  border: 1px solid #ddd;
  */
  border-right: 0px;
  display: block;
  text-align: center;
  float: left;
}

#prog_page .tabs li a, #prog_page .sub_items li a
{
  height: 20px;
  padding: 11px 35px;
  background: url(/staticelement/img/main/tab_r_bg.jpg) right no-repeat;
  /*
  border: 1px solid #ddd;
  */
  border-right: 0px;
  display: block;
  text-align: center;
  float: left;
  cursor: pointer;
}

#prog_page .panes > div
{
  clear: both;
}

#prog_page .sub_items li
{
  height: 42px;
  /*
  border: 1px solid #ddd;
  */
  border-right: 0px;
  display: block;
  text-align: center;
  float: left;
}

#buy_box
{
  margin-top: 65px;
}

.lest_prog_box
{
  height: 240px;
  position: relative;
}

.lest_prog_box .scrollable
{
  margin-left: 33px;
}

.lest_prog_box a.browse
{
  position: absolute;
  top: 50%;
  margin-top: -16px;
}

.lest_prog_box a.left
{
  margin-left: 0px;
}

.lest_prog_box a.right
{
  right: 0px;
}

.lest_prog_box .scrollable .s_item
{
  padding: 20px 10px 10px 10px;
}

#scrollable_box
{
  position: relative;
  border: 1px solid #f2f2f2;
  height: 200px;
}

/* video_list */

.video_list
{
  display: block;
  width: 100%;
  margin: 15px 0 0 0;
  overflow: hidden;
}

.video_list .video_item
{
  float: left;
  display: block;
  width: 50%;
  border-right: 1px solid #eee;
  padding: 0 15px;
  margin-bottom: 15px;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.video_list .video_item .video_image_wrapper
{
  position: relative;
  padding-bottom: 66.66667% !important;
}

.video_image_wrapper img
{
  width: 100%;
  height: 100%;
  position: absolute;
}

.video_list .video_item a
{
  display: block;
  width: 100%;
  overflow: hidden;
  color: #666;
}

.video_list .video_item:hover a
{
  color: #0082fe;
}

.video_list .video_item h4
{
  margin-top: 5px;
  font-size: 16px;
  height: 58px;
  line-height: 1.2;
  font-weight: normal;
}

/*heree kevin 2017*/
.video_list[data-eq-state="1-column-grid"] .video_item
{
  width: 100%;
}

.video_list[data-eq-state="3-column-grid"] .video_item
{
  width: 33.3333%;
}

.video_list[data-eq-state="4-column-grid"] .video_item
{
  width: 25%;
}

.video_list[data-eq-state="6-column-grid"] .video_item
{
  width: 16.66667%;
}

.open_alltxt
{
  cursor: pointer;
  text-align: center;
  color: #d22922;
  display: none;
}

/* 所有影片 */

#all_video
{
  padding-top: 10px;
}

/*
 __   __   __   __   __                      __         ___  __        __   ___
|__) |__) /  \ / _` |__)  /\   |\/|    |__| /  \  |\/| |__  |__)  /\  / _` |__
|    |  \ \__/ \__> |  \ /~~\  |  |    |  | \__/  |  | |___ |    /~~\ \__> |___
*/

/*

        CSS Design Pattern Implementation
------------------------------------------

some of the following CSS are implementations
of some of principle of CSS Design Pattern naming SMACSS, OOCSS
these settings will be used globally,
just dont move it to some where at least now.

1. dont bind html tag for css selector
2. setting the specific class name for a elem which's layout need to be overlay before
3. if request is changed, we hope only modify css without html

------------------------------------------

*/

.block_elem
{
  display: block;
}

.btn
{
  /* global setting for all btn */
  display: block;
  text-align: center;
  padding: 0;
}

.orange-text
{
  color: #f65f32;
}
.blue-text
{
  color: #0000CC;
}

.orange-bg
{
  background: #f65f32;
}
.blue-bg
{
  background: #0000CC;
}

.nav_all_prog_list a
{
  text-align: center;
}

.nav_all_prog_list a:hover
{
  cursor: pointer;
}

.nav_all_prog_list ul
{
  /* global setting for all ul */
  list-style-type: none;
}

.title_bar
{
  padding: 5px 0 5px 35px;
  background: #f63284;
  line-height: 1.35;
}

.title_bar h3
{
  color: #fff;
}

.title_description
{
  padding-left: 15px;
}

.nav_all_prog .arrow
{
  color: white;

  position: absolute;
  display: block;

  width: 34px;
  height: 73px;
  top: 0;
  line-height: 73px;
}

.nav_all_prog .right-arrow
{
  /* --- gap between div.nav_all_prog & browser edge --- */
  right: -10px;

  border-right: 10px solid white;
  background: url("../img/main/arrowright.png") no-repeat;
  background-size: 40% 30%;
  background-position: center center;
}

.nav_all_prog .left-arrow
{
  left: 0;
  background: url("../img/main/arrowleft.png") no-repeat;
  background-size: 40% 30%;
  background-position: center center;
}

#String_allProg
{
  margin-bottom: 17px;
}

.nav_all_prog
{
  font-size: 1.17em;

  position: relative;

  max-width: 967px;
  margin: 0;
  padding-left: 34px;
}

.nav_all_prog_list
{
  max-width: 817px;
  height: 73px;

  position: relative;
  overflow: hidden;
}

.nav_all_prog_list ul
{
  position: absolute;
  line-height: 73px;
  width: 100%;
}

.nav_all_prog_list li
{
  float: left;
  width: 115px;
}

.nav_all_prog_list li a
{
  color: white;
  display: block;
}

.nav_all_prog_list a:hover, .nav_all_prog li.active a
{
  background: white;
  color: #f65f32;
}

.nav_all_prog[data-eq-state="4-nav-link"] .nav_all_prog_list ul
{
  width: 100%;
}

.nav_all_prog[data-eq-state="5-nav-link"] .nav_all_prog_list ul
{
  width: 100%;
}

.nav_all_prog[data-eq-state="all-nav-link"]
{
  padding-left: 13px;
}

.nav_all_prog[data-eq-state="all-nav-link"] .arrow
{
  width: 0;
  overflow: hidden;
}

.nav_all_prog[data-eq-state="all-nav-link"] .nav_all_prog_list
{
  height: 35px;
}

.nav_all_prog[data-eq-state="all-nav-link"] .nav_all_prog_list ul
{
  height: 35px;
  line-height: 35px;
  width: 100%;
}

.nav_all_prog[data-eq-state="all-nav-link"] .nav_all_prog_list li
{
  height: 35px;
  line-height: 35px;
  width: 14.2857%;
}

.recom_prog-btn
{
  font-size: 1.17em;
  color: white;
  font-weight: bold;

  width: 200px;
  height: 48px;
  margin: 46px auto 40px;
  line-height: 48px;
}

.is-hide
{
  display: none !important;
}

/* merged from category.css */

#Category_Headlines
{
  position: relative;
  margin-top: 10px;
  overflow: hidden;
  float: left;
}

#Category_Headlines h2
{
  font-size: 1.125rem;
  font-size: 18px;

  margin-bottom: 17px;
}

@media (min-width: 450px)
{
  #Category_Headlines h2
  {
    font-size: 1.25rem;
    font-size: 20px;
  }
}

#Category_Headlines h3
{
  color: #0754c8;
  margin-bottom: 5px;
}

#Category_Headlines .s_item
{
  display: block;
  position: relative;
  z-index: 1;
  width: 320px;
  height: 197px;
  text-decoration: none;
  overflow: hidden;
  float: left;
}

#Category_Headlines img.category_main_img
{
  float: right;
  width: 320px;
  position: relative;
  z-index: 5;
  top: 12;
  left: 0px;
  margin-top: -8px;
}

#Category_Headlines .news_info
{
  border-top: 1px solid #b9d7f1;
  margin-left: 320px;
  padding: 15px 0 10px 15px;
  text-align: justify;
  text-justify: inter-ideograph;
}

#Category_Headlines .news_info a
{
  font-size: 0.9em;
}

#Category_Headlines .todayInfo
{
  position: absolute;
  top: 2px;
  top: -webkit-calc(2px);
  top: 2px\9;
  right: 1px;
  color: #333333;
  background: none;
  padding: 0px;
}

#Category_Headlines .todayInfo a:first-child
{
  margin-right: 15px;
}

#Category_Headlines .todayInfo a
{
  font-weight: normal;
  font-size: 0.9em;
  margin-left: 8px;
}

#Category_Headlines .related_news
{
  padding-top: 15px;
  border-top: 1px dashed #ddd;
}

/* v2014_retention */

#v2014_retention
{
  margin-top: 10px;
}

#v2014_retention .box_01
{
  width: 320px;
  margin-right: 10px;
  float: left;
  border-top: 0;
}

#v2014_retention .header
{
  border-bottom: 1px solid #ddd;
}

#v2014_retention .header h3
{
  color: #444444;
}

/* new Catagory page */

#Category_Headlines_02
{
  position: relative;
  margin-top: 10px;
  overflow: hidden;
  padding-bottom: 10px;
  float: left;
  width: 100%;
}

#Category_Headlines_02 h2
{
  color: #0453ca;
  margin-bottom: 10px;
}

#Category_Headlines_02 h3
{
  font-size: 1.375rem;
  font-size: 22px;
  margin-bottom: 10px;
}

#Category_Headlines_02 h3 a
{
  color: #174c9c;
}

#Category_Headlines_02 .article_time
{
  height: 15px;
}

#Category_Headlines_02 .article_time a
{
  color: #737373;
  font-size: 15px;
  line-height: 1;
  display: block;
}

#Category_Headlines_02 p
{
  margin-top: 10px;
  margin-bottom: 10px;
  /*height: 120px;*/
  max-height: 120px;
  overflow: hidden;
}

#Category_Headlines_02 p a
{
  color: black;
  font-size: 1rem;
  font-size: 16px;
  line-height: 1.5;
}

#Category_Headlines_02 .info
{
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  border: 1px solid #e6e6e6;
  border-top: none;
  border-radius: 0px 0px 8px 8px;

  background-color: #f4f4f4;

  padding: 10px;
}

a.head_img
{
  display: block;
  overflow: hidden;
  line-height: 0;
}

a.head_img img
{
  width: 100%;
}

@media (min-width: 650px)
{
  #Category_Headlines_02 p a
  {
    color: #8e8e8e;
  }

  #Category_Headlines_02 h3 a
  {
    color: black;
  }

  #Category_Headlines_02 .info
  {
    border: none;
    padding: 0;

    background-color: transparent;
  }

  a.head_img
  {
    float: left;
    margin-right: 15px;
  }

  a.head_img img
  {
    width: 420px;
    height: 280px;
  }
}

/* merged from channel.css */

#vedio_trail
{
  /*
  width: 300px;
  background: url(/staticelement/img/tabtop_bg.jpg) no-repeat top;
  */
  position: relative;
  top: 0;
}

#vedio_trail .tabs
{
  background: url(/staticelement/img/main/tab_button.png) repeat-x bottom;
  height: 45px;
  position: relative;
  /*
  z-index: 100;
  */
}

#vedio_trail .tabs li:first-child
{
  border-left: 1px solid #ddd;
}

#vedio_trail .tabs li
{
  width: 33.33333%;
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
  display: block;
  text-align: center;
  float: left;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#vedio_trail .tabs li a
{
  line-height: 33px;
  height: 33px;
  display: block;
  *display: block;
  *height: 33px;
  *padding-top: 4px;
  vertical-align: middle;
  background-color: #f4f4f4;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

#vedio_trail .tabs li a.current
{
  background-color: #b01415;
  color: #f4f4f4;
}

#vedio_trail .tabs li a .pointer
{
  display: none;
}

#vedio_trail .tabs li a.current .pointer
{
  /*
  position: absolute;
  left: 48%;
  */
  margin: 0 auto;
  top: 33px;
  width: 14px;
  height: 8px;
  background: url(/staticelement/img/main/current_02.png) no-repeat 0 0;
  overflow: hidden;
  z-index: 100;
  display: block;
}

#vedio_trail .panes
{
  position: relative;
  top: -11px;
}

#vedio_trail .panes div
{
  border: 1px solid #dddddd;
  background-color: #fff;
}

.ul_style_03
{
  list-style: none;
}

.ul_style_03 li
{
  position: relative;
  margin-bottom: 1px;
  border-bottom: 1px solid #ddd;
  padding: 10px 10px 0 21px;
  overflow: hidden;
  zoom: 1;
  background-color: #f4f4f4;
  color: #0354c9;
  cursor: pointer;
}

.ul_style_03 li:last-child
{
  margin-bottom: 0px;
  border-bottom: 0px;
}

.ul_style_03 li .time
{
  margin-left: 10px;
  font-size: 0.9em;
  color: #454545;
}

.ul_style_03 li a
{
  line-height: 1.35;
  text-decoration: none;
  font-weight: normal;
  color: #454545;
}

.ul_style_03 a.img_l_02 img
{
  height: 60px;
  margin-left: -15px;
  text-align: center;
}

.ul_style_03 li:hover, .ul_style_03 li.current:hover
{
  background-color: #454545;
}

.ul_style_03 li:hover a
{
  color: #fff;
  font-weight: bold;
}

.ul_style_03 li:hover .time, .ul_style_03 li.current:hover .time
{
  color: #fff;
}

.ul_style_03 li.current
{
  background-color: #e3e3e3;
}

.ul_style_03 li.current .title,.ul_style_03 li.current .time
{
  color: black;
  font-weight: bold;
}

.ul_style_03 li.current .playing
{
  display: table;
}

.ul_style_03 .vi_desc
{
  display: none;
}

.playing
{
  position: relative;
  top: -2px;
  display: none;
  color: #FFFFFF;
  margin-left: 5px;
  border: 1px solid #fff;
  padding: 1px 3px 1px 3px;
  text-shadow: none;
  background-color: #b12124;
  letter-spacing: 0.07em;
  font-size: 0.7em;
  font-weight: bold;
}

.more_link
{
  position: relative;
  color: #ae1414;
}

#footer_logo
{
  margin: 95px 0 0 20px;
}

.copyright
{
  position: relative;
  color: #a2a2a2;
  font-size: 0.8em;
}

#select_date_box > a
{
  cursor: pointer;
  float: left;
  text-align: center;
  padding: 5px 0;
  display: block;
  width: 30%;
  border: 1px solid #eee;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#select_date
{
  color: #fff;
  background-color: #b01415;
  margin-left: 0px;
  top: 0;
  left: 0;
  width: 100%;
  position: absolute;
  padding: 5px 0;
  text-align: center;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#select_date_box a.btn_disabled
{
  color: #c1c1c1;
}

a.btn_02:hover
{
  color: #b01415;
}

#select_date img
{
  position: relative;
  top: 2px;

  margin-right: 5px;
}

.todayInfo
{
  float: left;
  width: 40%;
  height: 33px;
  cursor: pointer;
  padding-top: 5px;
  border: 1px solid #eee;
  position: relative;
  overflow: hidden;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#select_date_box .todayInfo:hover a
{
  color: #eee;
}

#ui-datepicker-div
{
  z-index: 99999;
}

.tag_list.channel
{
  margin-bottom: 10px;
}

/* merged from schedule.css */

#page_head
{
  margin: 10px 0;
}

#page_head h1
{
  color: #6d6d6d;
  font-size: 1.3em;
  float: left;
}

#page_head .download_btn
{
  float: right;
  color: #6d6d6d;
  position: relative;
  top: 10px;
  margin-left: 15px;
}

#page_head .download_btn:hover
{
  text-decoration: underline;
}

#schedule_prog_page
{
  z-index: 9;
  border: 1px solid #e3e3e3;
  border-bottom: 2px solid #bab8b9;
  background: none;
  border-top: 0;
}

#schedule_scrollable_box
{
  margin-top: 20px;
  position: relative;
  top: -10px;
  border: 0;
  height: initial;
}

#schedule_scrollable
{
  border: 0;
  height: 90px;
}

#schedule_scrollable .s_item
{
  width: 109px;
  display: block;
  float: left;
  padding: 15px 8px 15px 8px;
  border: 1px solid #eee;
  height: 48px;
  text-decoration: none;
  overflow: hidden;
  background-color: #f7f7f7;
  margin-top: 10px;
  color: #6d6d6d;
}

.s_week
{
  font-weight: bold;
  font-size: 1.3em;
  display: inline-block;
}

.s_date
{
  font-size: 0.9em;
  display: inline-block;
}

#schedule_scrollable .current
{
  width: 109px;
  padding-top: 25px;
  margin-top: 0;
  border-left: 1px solid #eee;
  border-bottom: 1px solid #fff;
  background-color: #fff;
  color: #5897f6;
}

#schedule_scrollable .s_item:hover
{
  color: #5897f6;
  background-color: #fff;
}

#schedule_prog_page a.browse
{
  background: url(/staticelement/img/main/hori_large_02.jpg) no-repeat;
  display: block;
  margin-top: 10px;
  width: 46px;
  height: 80px;
  float: left;
  cursor: pointer;
  font-size: 1px;
}

/* right */

#schedule_prog_page a.right
{
  position: absolute;
  background-position: -46px 0;
  clear: right;
  margin-left: 0;
}

#schedule_prog_page a.right:hover
{
  background-position: -46px -80px;
}

#schedule_prog_page a.right:active
{
  background-position: -46px -80px;
}

/* left */

#schedule_prog_page a.left
{
  margin-left: 0;
}

#schedule_prog_page a.left:hover
{
  background-position: 0 -80px;
}

#schedule_prog_page a.left:active
{
  background-position: 0 -80px;
}

.panes .pane_item
{
  padding-bottom: 10px;
}

.pane_item .section
{
  color: #6d6d6d;
  float: left;
  margin-top: 25px;
  padding: 0 0 25px 25px;
  width: 100%;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@media (min-width: 600px)
{
  .pane_item .section
  {
    padding: 0 0 25px 70px;
  }
}

@media (min-width: 1015px)
{
  .pane_item .section
  {
    max-width: 490px;
    width: 100%;
  }
}

.pane_item .section h3
{
  font-size: 1.3em;
  margin-bottom: 30px;
}

.pane_item .section ul
{
  border-right: 1px solid #eee;
}

.pane_item .section ul li
{
  display: block;
  list-style-type: none;
  clear: both;
  margin-bottom: 10px;
}

.pane_item .section ul li .time
{
  display: block;
  float: left;
  margin-right: 30px;
  margin-bottom: 25px;
  font-weight: bold;
}

/*-- add_FB_Line_lightbox START --*/

#fb_popup
{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;

  z-index: 100001;
  display: none;
}

.fb-like-title
{
  color: #ffffff;
}

.line-like-title
{
  color: #ffffff;
}

.fb-like-decs
{
  color: #ffffff;
}

.fb_like_lightbox iframe
{
  transform: scale(1.5);
  -ms-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -o-transform: scale(1.5);
  -moz-transform: scale(1.5);
  transform-origin: top left;
  -ms-transform-origin: top left;
  -webkit-transform-origin: top left;
  -moz-transform-origin: top left;
  -webkit-transform-origin: top left;
}

.fb-liked-close
{
  text-align: center;
  margin-bottom: 10px;
  margin-top: 15px;
}

#fb_box
{
  background: #1d58df none repeat scroll 0 0;
  border: 3px solid #828282;
  border-radius: 5px;
  left: 50%;
  margin: -125px 0 0 -142px;
  position: fixed;
  top: 50%;
  width: 270px;

  z-index: 100003;
  display: none;
}

#fb_box .close
{
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 8px;
  cursor: pointer;
  color: #eaeaea;
  font: 700 14px/1 Tahoma, sans-serif;
  text-decoration: none;
  background: #828282;
}

.fb-liked-close a
{
  cursor: pointer;
  font-size: 13px;
  text-decoration: none;
  color: #bfcbe7;
}

.fb-like-title
{
  font-size: 17px;
  margin: 15px 0 10px 20px;
}


.line-like-title
{
  font-size: 14px;
  margin: 8px 0 10px 20px;
  padding-right: 14px;
}

.fb-like-decs
{
  font-size: 21px;
  font-weight: 700;
  margin: 7px 0 13px 20px;
}

.fb_like_lightbox
{
  float: left;
  margin-left: 20px;
}

.line-like-btn
{
  margin-left: 40px;
}

#fb-line-btns
{
  margin-top: 20px
}

.line-like-btn img
{
  width: 100px;
}

@media (min-width: 400px) and (max-width: 590px)
{
  #fb_box
  {
    position: fixed;
    top: 50%;
    left: 50%;
    margin: -125px 0px 0 -189px;
    width: 370px;
    background: #1d58df;
    border: 3px solid #828282;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }

  #fb_box .close
  {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px 8px;
    cursor: pointer;
    color: #eaeaea;
    font: 700 14px/1 Tahoma, sans-serif;
    text-decoration: none;
    background: #828282;
  }

  .fb-liked-close a
  {
    cursor: pointer;
    font-size: 13px;
    text-decoration: none;
    color: #bfcbe7;
  }

  .fb-like-title
  {
    font-size: 19px;
    margin: 15px 0 20px 27px;
  }

  .line-like-title
  {
    font-size: 15px;
    margin: 8px 0 10px 27px;
  }

  .fb-like-decs
  {
    font-size: 26px;
    font-weight: 700;
    margin: 7px 0 20px 27px;
  }

  .fb_like_lightbox iframe
  {
    transform: scale(1.6);
    -ms-transform: scale(1.6);
    -webkit-transform: scale(1.6);
    -o-transform: scale(1.6);
    -moz-transform: scale(1.6);
    transform-origin: top left;
    -ms-transform-origin: top left;
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -webkit-transform-origin: top left;
  }

  .fb_like_lightbox
  {
    margin-left: 43px;
    float: left;
  }

  .line-like-btn
  {
    margin-left: 85px;
  }

  #fb-line-btns
  {
    margin-top: 20px
  }

  .line-like-btn img
  {
    width: 107px;
  }
}

@media (min-width: 590px)
{
  #fb_box
  {
    position: fixed;
    top: 50%;
    left: 50%;
    margin: -125px 0 0 -265px;
    width: 500px;
    background: #1d58df;
    border: 5px solid #828282;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }

  #fb_box .close
  {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px 8px;
    cursor: pointer;
    color: #eaeaea;
    font: 700 14px/1 Tahoma, sans-serif;
    text-decoration: none;
    background: #828282;
  }

  .fb_like_lightbox iframe
  {
    transform: scale(1.6);
    -ms-transform: scale(1.6);
    -webkit-transform: scale(1.6);
    -o-transform: scale(1.6);
    -moz-transform: scale(1.6);
    transform-origin: top left;
    -ms-transform-origin: top left;
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -webkit-transform-origin: top left;
  }

  .fb-liked-close a
  {
    cursor: pointer;
    font-size: 13px;
    text-decoration: none;
    color: #bfcbe7;
  }

  .fb-like-title
  {
    font-size: 25px;
    margin: 18px 0 13px 65px;
  }

  .line-like-title
  {
    font-size: 16px;
    margin: 8px 0 10px 65px;
  }

  .fb-like-decs
  {
    font-size: 30px;
    font-weight: 700;
    margin: 7px 0 15px 65px;
  }

  .fb_like_lightbox
  {
    margin-left: 90px;
    float: left;
  }

  .line-like-btn
  {
    margin-left: 110px;
  }

  #fb-line-btns
  {
    margin-top: 20px
  }

  .line-like-btn img
  {
    width: 107px;
  }
}
/*-- add_FB_Line_lightbox END --*/

/* 大專題頁 css for slick.js 20170205*/
.slick-slider
{
  position: relative;

  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list
{
  position: relative;

  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}
.slick-list:focus
{
  outline: none;
}
.slick-list.dragging
{
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track
{
  position: relative;
  top: 0;
  left: 0;

  display: block;
}
.slick-track:before,
.slick-track:after
{
  display: table;

  content: '';
}
.slick-track:after
{
  clear: both;
}
.slick-loading .slick-track
{
  visibility: hidden;
}

.slick-slide
{
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .slick-slide
{
  float: right;
}
.slick-slide img
{
  display: block;
}
.slick-slide.slick-loading img
{
  display: none;
}
.slick-slide.dragging img
{
  pointer-events: none;
}
.slick-initialized .slick-slide
{
  display: block;
}
.slick-loading .slick-slide
{
  visibility: hidden;
}
.slick-vertical .slick-slide
{
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
/* css for slick.js  END */

/* 小專題頁  20170205 */
.topic_head_title
{
  line-height: 2.2;
  margin-bottom: 20px;
  text-align: center;

  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#134c9b+0,1758bb+50,134c9b+100 */
  background: #134c9b; /* Old browsers */
  background: -moz-linear-gradient(top, #134c9b 0%, #1758bb 50%, #134c9b 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #134c9b 0%,#1758bb 50%,#134c9b 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #134c9b 0%,#1758bb 50%,#134c9b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#134c9b', endColorstr='#134c9b',GradientType=0 ); /* IE6-9 */

}

.topic_head_title h1
{
  color: #fff;
  cursor: pointer;
  font-size: 40px;
}

.topic_head_title h1:hover
{
  text-decoration: underline;
  /*color: #e96e35;*/
}

/*
.popup_lightbox
{
  background: #ffffff none repeat scroll 0 0;
  border: 0;
  border-radius: 5px;
  left: 50%;
  margin: -125px 0 0 -142px;
  position: fixed;
  top: 33%;
  width: 215px;
  padding:30px;
  z-index: 100003;
  color: #272525cc;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #f4f5f6;
  border: 3px solid #ffffff;
  overflow-y: auto;
  max-height: 340px;
}
*/

.popup_lightbox
{
  position: absolute;
  margin: 0 auto;
  /*top: 10%;*/
  left: 10%;
  width: 80%;
  padding: 30px;
  z-index: 100003;
  
  border: 3px solid #ffffff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  
  background: #f4f5f6;
  
  overflow-y: auto;
  max-height: 340px;
  
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  
  -webkit-overflow-scrolling: touch;
}

.close
{
  position: absolute;
  top: 7px;
  right: 7px;
  padding: 5px 8px;
  cursor: pointer;
  color: #eaeaea;
  font: 700 14px/1 Tahoma, sans-serif;
  text-decoration: none;
  background: #828282;
}

.popup_lightbox > h2
{
  text-align: center;
  border-bottom: 1px solid #757575;
  margin: 10px 0;
  margin: 0px 0 26px 0;
  padding-bottom: 20px;
  font-weight: unset;
  font-size: 24px;
}

@media (min-width: 400px) and (max-width: 590px)
{
  /*
  .popup_lightbox
  {
    position: fixed;
    top: 33%;
    margin: -125px 0px 0 -189px;
    width: 300px;
  }
  */
}

@media (min-width: 590px)
{
  /*
  .popup_lightbox
  {
    position: fixed;
    top: 33%;
    margin: -125px 0 0 -265px;
    width: 475px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }
  */
}

@media (min-width: 1200px)
{
  .popup_lightbox
  {
    left: 25%;
    width: 50%;
  }
}
/* 小專題頁  END */


/*
大專題頁 20170205
*/

.topic_cate_list .video_item img
{
  left: 0;
  top: 0;
}

#nav_topic_list a:hover,#nav_topic_list li.active a
{
  color: #4622f6;
  background: white;
}

#nav_topic_list a
{
  color: #ffffff;
}

#nav_topic_list li:hover,#nav_topic_list li.active
{
  background: white;
}

#nav_topic_list li
{
  height: 60px;
  line-height: 60px;
  text-align: center;
  width: 8.9%;
}

.nav_all_prog[data-eq-state="all-nav-link"] #nav_topic_list li
{
  width: 8.9%;
}

.nav_all_prog[data-eq-state="7-nav-link"] #nav_topic_list li
{
  width: 13%;
}

.nav_all_prog[data-eq-state="5-nav-link"] #nav_topic_list li
{
  width: 17%;
}

.nav_all_prog[data-eq-state="3-nav-link"] #nav_topic_list li
{
  width: 26%;
}

.nav_all_prog[data-eq-state="2-nav-link"] #nav_topic_list li
{
  width: 40%;
}

#nav_topic_list
{
  font-size: 1.2em;
  max-width: unset;
  padding-left: 16px;
  padding-right: 35px;
}

#nav_topic_list
{
  height: 60px;
  position: relative;
  overflow: hidden;
}

#nav_all_topic .arrow
{
  height: 61px;
}

@media (min-width: 930px)
{
  #nav_topic_list li
  {
    height: 38px;
    line-height: 38px;
    text-align: center;
    width: 8.9%;
  }

  #nav_topic_list
  {
    padding-left: 4px;
    padding-right: 4px;
    height: 38px;
  }
}

#nav_topic_list .arrow
{
  color: white;
  position: absolute;
  display: block;
  width: 34px;
  height: 60px;
  top: 0;
  line-height: 60px;
}

#nav_topic_list .left-arrow
{
  left: 0;
  background: url(../img/main/arrowleft.png) no-repeat;
  background-size: 40% 30%;
  background-position: center center;
}

#nav_topic_list .right-arrow
{
  right: -10px;
  border-right: 10px solid white;
  background: url(../img/main/arrowright.png) no-repeat;
  background-size: 40% 30%;
  background-position: center center;
}

div.video_image_wrapper .topic_info
{
  display: block;
  padding: 15px 15px 15px 10px;
  font-size: 1em;
  color: White;
  font-weight: 700;
  background: url(/staticelement/img/main/opacity_bg.png) repeat;
  z-index: 10;
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

div.video_image_wrapper .topic_desc
{
  display: block;
  font-size: 0.9em;
  line-height: 1.8em;
  padding-top: 4em;
  overflow: hidden;
  word-break: break-all;
  /*width: 432px;*/
  height: 10em;
}

div.video_image_wrapper  .topic_desc h3
{
  border-bottom: 1px solid #ddd;
  margin: 7px 0 15px;
}

.topic_cate_list .video_list .video_item
{
  border-right: none;
  padding:0;
}

@media (min-width: 1010px)
{
  .topic_cate_list .video_list .video_item
  {
    padding: 0 8px;
  }

  div.video_image_wrapper .topic_desc
  {
    font-size: 18px;
    padding-top: 30px;
    line-height: 28px;
    height: 270px;
    padding-top: 15px;
    padding: 30px 0px 7px 9px;
  }

  div.video_image_wrapper .topic_info
  {
    padding: 18px 15px 18px 10px;
    font-size: 1.2em;
  }

  #topic_list .video_list .video_item
  {
    border-right: none;
    padding:0 10px;
  }
}

@media (min-width: 850px) and (max-width: 1010px)
{
  .topic_cate_list .video_list .video_item
  {
    padding: 0 8px;
  }

  div.video_image_wrapper .topic_desc
  {
    font-size: 1em;
    line-height: 2.3em;
    padding-top: 4em;
    height: 12.5em;
  }

  div.video_image_wrapper .topic_info
  {
    padding: 15px 15px 15px 10px;
    font-size: 1.2em;
  }

  #topic_list .video_list .video_item
  {
    border-right: none;
    padding:0 10px;
  }
}

@media (min-width: 700px) and (max-width: 850px)
{
  .topic_cate_list .video_list .video_item
  {
    padding: 0 8px;
  }

  div.video_image_wrapper .topic_desc
  {
    font-size: 0.9em;
    line-height: 2em;
    padding-top: 4em;
    height: 10em;
  }

  div.video_image_wrapper .topic_info
  {
    padding: 15px 15px 15px 10px;
    font-size: 1.2em;
  }

  #topic_list .video_list .video_item
  {
    border-right: none;
    padding:0 10px;
  }
}

@media (min-width: 600px) and (max-width: 700px)
{
  .topic_cate_list .video_list .video_item
  {
    padding: 0 8px;
  }

  div.video_image_wrapper .topic_desc
  {
    font-size: 0.9em;
    line-height: 1.8em;
    padding-top: 4em;
    height: 8em;
  }

  div.video_image_wrapper .topic_info
  {
    padding: 15px 15px 15px 10px;
    font-size: 1.2em;
  }

  #topic_list .video_list .video_item
  {
    border-right: none;
    padding:0 5px;
  }
}

@media (min-width: 480px) and (max-width: 600px)
{
  .topic_cate_list .video_list .video_item
  {
    padding: 0 4px;
  }

  div.video_image_wrapper .topic_desc
  {
    font-size: 0.9em;
    line-height: 1.5em;
    padding-top: 4em;
    height: 5em;
  }

  div.video_image_wrapper .topic_info
  {
    padding: 15px 15px 15px 10px;
    font-size: 1.2em;
  }

  #topic_list .video_list .video_item
  {
    border-right: none;
    padding:0 5px;
  }
}

@media (min-width: 375px) and (max-width: 479px)
{
  .topic_cate_list .video_list .video_item
  {
    padding: 0;
  }

  div.video_image_wrapper .topic_desc
  {
    font-size: 0.9em;
    line-height: 1.7em;
    padding-top: 5em;
    height: 11em;
  }

  div.video_image_wrapper .topic_info
  {
    padding: 15px 15px 15px 10px;
    font-size: 1.2em;
  }

  #topic_list .video_list .video_item
  {
    border-right: none;
    padding:0;
  }
}

/*
文章頁 for 專題 20170205
*/

.topic_keywords
{
  margin: 10px 0 0 0;
}

.topic_keywords a:hover
{
  color: #0753ca;
}

.topic_keywords a
{
  margin: 0 14px;
  font-size: 1.125rem;
  font-size: 18px;
  color: #939393;
  line-height: 28px;
  
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* this is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* instead use this non-standard one: */
  word-break: break-word;
}

.topic_keywords a.topic_icon
{
  background-color: #164b9b;
  color: #fff;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  font-weight: bold;
  cursor: pointer;
  margin: 0px;
  display: inline-block;
  padding: 0px 12px;
}

/*
首頁上方主表單 for 專題 20170205
*/

#topic_header_menu .dropdown
{
  position: absolute;
  top: 36px;
  left: -485px;
  height: 210px;
}

#topic_header_menu .dropdown h3
{
  font-size: 1em;
  padding: 0px 21px;
}

#topic_header_menu .dropdown h4
{
  color: #e96e35;
}

#topic_header_menu .dropdown .lastnews
{
  padding-right: 6px;
  padding-left: 9px;
  width: auto;
}

#headerMenu #topic_header_menu .item
{
  padding: 6px 21px 6px 22px;
}

#topic_header_menu .dropdown .newsCategory
{
  margin-right: 5px;
}

#topic_header_menu .lastnews.newtopic
{
  border-right: none;
  padding-right:0px;
}
